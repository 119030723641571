import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _vm.labInfo
        ? _c(
            "div",
            [
              _c(
                VCol,
                [
                  _c(
                    "div",
                    {
                      style: _vm.selectedUser
                        ? "margin-left -0.8%"
                        : "margin:10px 0 10px -0.8%",
                      attrs: { width: _vm.selectedUser ? "49.8%" : "100%" },
                    },
                    [
                      _c(
                        "b",
                        {
                          staticStyle: {
                            "font-size": "24px",
                            "margin-left": "5px",
                            position: "absolute",
                            top: "5px",
                          },
                          style: !_vm.openWideScreen ? "display:none" : "",
                        },
                        [_vm._v("Lab: " + _vm._s(_vm.labInfo.labName))]
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { width: "400", height: "400" },
                          model: {
                            value: _vm.clearDialog,
                            callback: function ($$v) {
                              _vm.clearDialog = $$v
                            },
                            expression: "clearDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticClass: "headline red lighten-2",
                                  attrs: { "primary-title": "" },
                                },
                                [
                                  _vm._v(
                                    "\n                            랩실 초기화\n                        "
                                  ),
                                ]
                              ),
                              _vm.deleting
                                ? _c(
                                    VCardText,
                                    [
                                      _c(VProgressCircular, {
                                        attrs: {
                                          indeterminate: "",
                                          color: "red",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                            삭제중 입니다.\n                        "
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    VCardText,
                                    [
                                      _c(VCheckbox, {
                                        attrs: { label: `Config 유지 시 체크` },
                                        model: {
                                          value: _vm.maintainConfig,
                                          callback: function ($$v) {
                                            _vm.maintainConfig = $$v
                                          },
                                          expression: "maintainConfig",
                                        },
                                      }),
                                      _c(VCheckbox, {
                                        attrs: { label: `파일 유지 시 체크` },
                                        model: {
                                          value: _vm.maintainFiles,
                                          callback: function ($$v) {
                                            _vm.maintainFiles = $$v
                                          },
                                          expression: "maintainFiles",
                                        },
                                      }),
                                      _vm._v(
                                        "\n                            삭제 시, 옵션을 확인해주세요.\n                        "
                                      ),
                                    ],
                                    1
                                  ),
                              _c(VDivider),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearLab(_vm.selectedUser)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                삭제\n                            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.clearDialog = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                취소\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          style: !_vm.openWideScreen
                            ? "display:none"
                            : "margin-left:-15px;",
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.missionDialog = true
                            },
                          },
                        },
                        [
                          _c(
                            VIcon,
                            { staticStyle: { margin: "2px 5px 0 0" } },
                            [_vm._v("mdi-flag")]
                          ),
                          _vm._v("미션\n                "),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { width: "1000", height: "800" },
                          model: {
                            value: _vm.missionDialog,
                            callback: function ($$v) {
                              _vm.missionDialog = $$v
                            },
                            expression: "missionDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticClass: "headline green lighten-2",
                                  attrs: { "primary-title": "" },
                                },
                                [
                                  _vm._v(
                                    "\n                            미션\n                        "
                                  ),
                                ]
                              ),
                              _c(
                                VCardText,
                                [
                                  _c("vue-markdown", {
                                    staticClass: "markdown-body",
                                    attrs: {
                                      source: _vm.labInfo.instructionHtml,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(VDivider),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.missionDialog = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Close\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          style: !_vm.openWideScreen
                            ? "display:none"
                            : "margin-left:-15px;",
                          attrs: { color: "primary", text: "", large: "" },
                          on: {
                            click: function ($event) {
                              _vm.theoryDialog = true
                            },
                          },
                        },
                        [
                          _c(
                            VIcon,
                            { staticStyle: { margin: "2px 5px 0 0" } },
                            [_vm._v("mdi-file-chart")]
                          ),
                          _vm._v("이론/자료\n                "),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: {
                            width: _vm.theoryExtended ? 1240 : 1000,
                            height: "800",
                          },
                          model: {
                            value: _vm.theoryDialog,
                            callback: function ($$v) {
                              _vm.theoryDialog = $$v
                            },
                            expression: "theoryDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticClass: "headline grey lighten-2",
                                  attrs: { "primary-title": "" },
                                },
                                [
                                  _vm._v(
                                    "\n                            이론/자료\n                            "
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", sm: "3" } },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            icon: "",
                                            color: _vm.theoryExtended
                                              ? "indigo"
                                              : "blue",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.theoryExtended =
                                                !_vm.theoryExtended
                                            },
                                          },
                                        },
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-file-chart"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                [
                                  _c(
                                    VRow,
                                    [
                                      _c(VCol, { attrs: { width: "500" } }, [
                                        _c("div", {
                                          staticClass: "py-3",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.labInfo.theoryHtml
                                            ),
                                          },
                                        }),
                                      ]),
                                      _vm.theoryExtended
                                        ? _c(
                                            VCol,
                                            [
                                              _vm.isAdmin
                                                ? _c(
                                                    VRow,
                                                    [
                                                      _c(
                                                        VCol,
                                                        [
                                                          _c(VTextField, {
                                                            attrs: {
                                                              type: "number",
                                                              label:
                                                                "상금 코인",
                                                            },
                                                            model: {
                                                              value: _vm.prize,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.prize =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "prize",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        VCol,
                                                        {
                                                          attrs: { right: "" },
                                                        },
                                                        [
                                                          _vm.$refs.question
                                                            ? _c(
                                                                VBtn,
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "teal",
                                                                    icon: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.$refs
                                                                        .question
                                                                        .clearMessages,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    VIcon,
                                                                    {
                                                                      attrs: {
                                                                        alt: "설문결과삭제",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "delete"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("poll-result", {
                                                on: {
                                                  answered: _vm.onQuizAnswered,
                                                },
                                                model: {
                                                  value: _vm.userMessages,
                                                  callback: function ($$v) {
                                                    _vm.userMessages = $$v
                                                  },
                                                  expression: "userMessages",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(VDivider),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.theoryDialog = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Close\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.links, function (link, key) {
                        return _c(VBtn, { key: key, attrs: { text: "" } }, [
                          _c("a", { attrs: { href: link, target: "_blank" } }, [
                            _vm._v(_vm._s(key)),
                          ]),
                        ])
                      }),
                      _vm.isAdmin
                        ? _c(
                            VBtn,
                            {
                              style: !_vm.openWideScreen ? "display:none" : "",
                              attrs: { color: "primary", text: "", large: "" },
                              on: {
                                click: function ($event) {
                                  _vm.pollDialog = true
                                },
                              },
                            },
                            [
                              _c(
                                VIcon,
                                { staticStyle: { margin: "2px 5px 0 0" } },
                                [_vm._v("mdi-poll")]
                              ),
                              _vm._v("설문\n                "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c(
                            VDialog,
                            {
                              attrs: { width: "1000", height: "800" },
                              model: {
                                value: _vm.pollDialog,
                                callback: function ($$v) {
                                  _vm.pollDialog = $$v
                                },
                                expression: "pollDialog",
                              },
                            },
                            [
                              _c(
                                VCard,
                                [
                                  _c(
                                    VCardTitle,
                                    {
                                      staticClass: "headline grey lighten-2",
                                      attrs: { "primary-title": "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            설문\n\n                        "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    VCardText,
                                    [
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VCol,
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  type: "number",
                                                  label: "상금 코인",
                                                },
                                                model: {
                                                  value: _vm.prize,
                                                  callback: function ($$v) {
                                                    _vm.prize = $$v
                                                  },
                                                  expression: "prize",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VCol,
                                            { attrs: { right: "" } },
                                            [
                                              _vm.$refs.question
                                                ? _c(
                                                    VBtn,
                                                    {
                                                      attrs: {
                                                        tile: "",
                                                        large: "",
                                                        color: "teal",
                                                        icon: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.$refs.question
                                                            .clearMessages,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          attrs: {
                                                            alt: "설문결과삭제",
                                                          },
                                                        },
                                                        [_vm._v("delete")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("poll-result", {
                                        on: { answered: _vm.onQuizAnswered },
                                        model: {
                                          value: _vm.userMessages,
                                          callback: function ($$v) {
                                            _vm.userMessages = $$v
                                          },
                                          expression: "userMessages",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(VDivider),
                                  _c(
                                    VCardActions,
                                    [
                                      _c(VSpacer),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { color: "primary", text: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.pollDialog = false
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Close\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VDialog,
                        {
                          attrs: { width: "500", height: "300" },
                          model: {
                            value: _vm.answerDialog,
                            callback: function ($$v) {
                              _vm.answerDialog = $$v
                            },
                            expression: "answerDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticClass: "headline grey lighten-2",
                                  attrs: { "primary-title": "" },
                                },
                                [
                                  _vm._v(
                                    "\n                            응답\n                        "
                                  ),
                                ]
                              ),
                              _c(
                                VCardText,
                                [
                                  _c("answer", {
                                    attrs: {
                                      "lab-info": _vm.labInfo,
                                      "class-info": _vm.classInfo,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(VDivider),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.answerDialog = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Close\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: { display: "contents" },
                          style: _vm.selectedUser ? "display:none" : "",
                        },
                        [
                          _vm.isAdmin
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "btn-group",
                                  style: !_vm.openWideScreen
                                    ? "display:none"
                                    : "",
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    large: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addGroup()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    VIcon,
                                    { staticStyle: { margin: "2px 5px 0 0" } },
                                    [_vm._v("mdi-account-multiple-plus")]
                                  ),
                                  _vm._v("그룹 추가\n                    "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isAdmin && _vm.labInfo.tool == "ide"
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "btn-batch-execution",
                                  style: !_vm.openWideScreen
                                    ? "display:none"
                                    : "",
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    large: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.initLabIdeAll()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    VIcon,
                                    { staticStyle: { margin: "2px 5px 0 0" } },
                                    [_vm._v("mdi-play-circle")]
                                  ),
                                  _vm._v("일괄 실행\n                    "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "coin-drop",
                            {
                              ref: "coinDrop",
                              staticStyle: { "z-index": "999" },
                              on: {
                                drop: _vm.deposit,
                                allDrop: _vm.coinAllDropped,
                              },
                              model: {
                                value: _vm.scoreToBeDeposited,
                                callback: function ($$v) {
                                  _vm.scoreToBeDeposited = $$v
                                },
                                expression: "scoreToBeDeposited",
                              },
                            },
                            [
                              _c("div", {
                                attrs: { slot: "default" },
                                slot: "default",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      [
                        _c(
                          "div",
                          { staticClass: "class-btn-menu" },
                          [
                            _c(
                              VMenu,
                              {
                                attrs: {
                                  "offset-y": "",
                                  "open-on-hover": "",
                                  left: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            VBtn,
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "class-list-btn",
                                                  staticStyle: {
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "37px",
                                                  },
                                                  style: !_vm.openWideScreen
                                                    ? "display:none"
                                                    : "",
                                                  attrs: {
                                                    color: "primary",
                                                    icon: "",
                                                    large: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticStyle: {
                                                    margin: "2px 5px 0 0",
                                                  },
                                                },
                                                [_vm._v("mdi-dots-horizontal")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1399150772
                                ),
                              },
                              [
                                _c(
                                  VList,
                                  { attrs: { width: "140px" } },
                                  [
                                    _c(
                                      VListItem,
                                      {
                                        staticClass: "class-btn-answer",
                                        on: {
                                          click: function ($event) {
                                            _vm.answerDialog = true
                                          },
                                        },
                                      },
                                      [
                                        _c(VListItemTitle, [
                                          _vm._v("응답"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      VListItem,
                                      {
                                        staticClass: "class-btn-lab-list",
                                        attrs: {
                                          to:
                                            "/courses/" +
                                            _vm.courseId +
                                            "/" +
                                            _vm.classId,
                                        },
                                      },
                                      [
                                        _c(VListItemTitle, [
                                          _vm._v("랩 목록"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm.isAdmin
                                      ? _c(
                                          VListItem,
                                          {
                                            staticClass: "class-btn-group",
                                            on: {
                                              click: function ($event) {
                                                return _vm.addGroup()
                                              },
                                            },
                                          },
                                          [
                                            _c(VListItemTitle, [
                                              _vm._v("그룹 추가"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isAdmin && _vm.labInfo.tool == "ide"
                                      ? _c(
                                          VListItem,
                                          {
                                            staticClass:
                                              "class-btn-batch-execution",
                                            on: {
                                              click: function ($event) {
                                                return _vm.initLabIdeAll()
                                              },
                                            },
                                          },
                                          [
                                            _c(VListItemTitle, [
                                              _vm._v("일괄 실행"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              left: "50%",
                              top: "0",
                            },
                            style: _vm.selectedUser
                              ? "display:block"
                              : "display:none",
                          },
                          [
                            _c(
                              VMenu,
                              {
                                attrs: {
                                  "offset-y": "",
                                  "open-on-hover": "",
                                  left: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            VBtn,
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "class-list-btn",
                                                  staticStyle: {
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "37px",
                                                  },
                                                  style: !_vm.openWideScreen
                                                    ? "display:none"
                                                    : "",
                                                  attrs: {
                                                    color: "primary",
                                                    icon: "",
                                                    large: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticStyle: {
                                                    margin: "2px 5px 0 0",
                                                  },
                                                },
                                                [_vm._v("mdi-dots-horizontal")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1399150772
                                ),
                              },
                              [
                                _c(
                                  VList,
                                  { attrs: { width: "140px" } },
                                  [
                                    _vm.isAdmin
                                      ? _c(
                                          VListItem,
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.addGroup()
                                              },
                                            },
                                          },
                                          [
                                            _c(VListItemTitle, [
                                              _vm._v("그룹 추가"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isAdmin && _vm.labInfo.tool == "ide"
                                      ? _c(
                                          VListItem,
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.initLabIdeAll()
                                              },
                                            },
                                          },
                                          [
                                            _c(VListItemTitle, [
                                              _vm._v("일괄 실행"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                  _vm.changedUser
                    ? _c(
                        VDialog,
                        {
                          attrs: { persistent: "", "max-width": "450" },
                          model: {
                            value: _vm.changedUserDialog,
                            callback: function ($$v) {
                              _vm.changedUserDialog = $$v
                            },
                            expression: "changedUserDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(VCardTitle, { staticClass: "headline" }, [
                                _vm._v("유저 수정 및 삭제"),
                              ]),
                              _c(
                                VCardText,
                                [
                                  Object.values(_vm.changedUser)[0].photoURL ==
                                  undefined
                                    ? _c(
                                        VIcon,
                                        { attrs: { "x-large": "" } },
                                        [
                                          _vm._v(
                                            "\n                            mdi-account-circle\n                        "
                                          ),
                                        ]
                                      )
                                    : _c(VAvatar, { staticClass: "ma-0" }, [
                                        _c("img", {
                                          attrs: {
                                            src: Object.values(
                                              _vm.changedUser
                                            )[0].photoURL,
                                          },
                                        }),
                                      ]),
                                  _c(VTextField, {
                                    attrs: { label: "Email", disabled: "" },
                                    model: {
                                      value: Object.values(_vm.changedUser)[0]
                                        .email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          Object.values(_vm.changedUser)[0],
                                          "email",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "Object.values(changedUser)[0].email",
                                    },
                                  }),
                                  _c(VTextField, {
                                    attrs: { label: "Name" },
                                    model: {
                                      value: Object.values(_vm.changedUser)[0]
                                        .userName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          Object.values(_vm.changedUser)[0],
                                          "userName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "Object.values(changedUser)[0].userName",
                                    },
                                  }),
                                  _c(VSelect, {
                                    attrs: {
                                      disabled: !_vm.isAdmin,
                                      items: _vm.groupList,
                                      label: "Group",
                                    },
                                    model: {
                                      value: Object.values(_vm.changedUser)[0]
                                        .group,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          Object.values(_vm.changedUser)[0],
                                          "group",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "Object.values(changedUser)[0].group",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _vm.isAdmin
                                    ? _c(
                                        VBtn,
                                        {
                                          attrs: { text: "", color: "red" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.changedUserAction(
                                                "delete"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("삭제")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { text: "" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.closeChangedUserDialog()
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("word.close")))]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        text: "",
                                        bottom: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changedUserAction("modify")
                                        },
                                      },
                                    },
                                    [_vm._v("수정")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                VRow,
                { staticStyle: { "margin-top": "-35px" } },
                [
                  _c(VSubheader, [_vm._v("Class Room Status")]),
                  _c(
                    "div",
                    {
                      staticClass: "ma-5",
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-top": "0px",
                        "margin-bottom": "0px",
                      },
                    },
                    [
                      _vm.deadline &&
                      _vm.labStatus &&
                      _vm.labStatus.status == "started"
                        ? _c("Countdown", { attrs: { deadline: _vm.deadline } })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "div",
                    { staticStyle: { "margin-left": "-50px" } },
                    [
                      _vm.isAdmin &&
                      (_vm.labStatus.status == "READY" ||
                        _vm.labStatus.status == "completed")
                        ? _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticStyle: { padding: "5px" },
                                                attrs: {
                                                  color: "green",
                                                  text: "",
                                                  large: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    ;[
                                                      _vm.startLab("start"),
                                                      _vm.scoreStart(),
                                                    ]
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              VIcon,
                                              {
                                                staticStyle: {
                                                  margin: "2px 5px 0 0",
                                                },
                                              },
                                              [_vm._v("mdi-play")]
                                            ),
                                            _vm._v(
                                              "시작\n                        "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2057490081
                              ),
                            },
                            [_c("span", [_vm._v("랩 스코어 시작")])]
                          )
                        : _vm._e(),
                      _vm.isAdmin && _vm.labStatus.status == "started"
                        ? _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "btn-end",
                                                staticStyle: { padding: "5px" },
                                                attrs: {
                                                  color: "red",
                                                  text: "",
                                                  large: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    ;[
                                                      _vm.completeLab(),
                                                      _vm.scoreTimeStop(),
                                                    ]
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              VIcon,
                                              {
                                                staticStyle: {
                                                  margin: "2px 5px 0 0",
                                                },
                                              },
                                              [_vm._v("mdi-checkbox-blank")]
                                            ),
                                            _vm._v(
                                              "종료\n                        "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3442244899
                              ),
                            },
                            [_c("span", [_vm._v("랩 스코어 종료")])]
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                position: "relative",
                                top: "2px",
                                "margin-left": "5px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.scoreTime.time))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                [
                  _c(VTextField, {
                    staticStyle: {
                      margin: "0 0 -25px 10px",
                      "max-width": "300px",
                    },
                    attrs: {
                      placeholder: "Search User",
                      filled: "",
                      rounded: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c(
                VRow,
                [
                  _vm._l(_vm.getGroupUsers, function (group, key, groupIdx) {
                    return group && !group.userName
                      ? _c(
                          VCol,
                          { key: key, staticClass: "sortable" },
                          [
                            _c(
                              VCard,
                              {
                                style: _vm.openWideScreen
                                  ? "height:800px; overflow:auto"
                                  : "height:100%",
                                attrs: {
                                  width:
                                    Object.keys(_vm.classInfo.groupedUsers)
                                      .length == 1
                                      ? _vm.selectedUser
                                        ? "49%"
                                        : "100%"
                                      : "350px",
                                },
                              },
                              [
                                _vm.editGroupIdx == groupIdx
                                  ? _c(
                                      VCardTitle,
                                      [
                                        _c(VTextField, {
                                          attrs: { label: "Group Name" },
                                          on: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.submitGroupName(
                                                groupIdx,
                                                key
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.editGroupName,
                                            callback: function ($$v) {
                                              _vm.editGroupName = $$v
                                            },
                                            expression: "editGroupName",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      VCardTitle,
                                      {
                                        on: {
                                          dblclick: function ($event) {
                                            return _vm.editMode(groupIdx, key)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          VTooltip,
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _vm.getMemberCount
                                                        .basicPassUserCount == 0
                                                        ? _c(
                                                            "div",
                                                            _vm._g({}, on),
                                                            [
                                                              _vm._v(
                                                                "\n                                    " +
                                                                  _vm._s(key) +
                                                                  "\n                                    (" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .getMemberCount
                                                                      .allPassUserCount
                                                                  ) +
                                                                  "/" +
                                                                  _vm._s(
                                                                    _vm.labResultCount
                                                                  ) +
                                                                  "/" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .getMemberCount
                                                                      .attendanceUserCount
                                                                  ) +
                                                                  "/" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .getMemberCount
                                                                      .connectionUserCount
                                                                  ) +
                                                                  "/" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .getMemberCount
                                                                      .usersCount
                                                                  ) +
                                                                  ")\n                                "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            _vm._g({}, on),
                                                            [
                                                              _vm._v(
                                                                "\n                                    " +
                                                                  _vm._s(key) +
                                                                  "\n                                    (" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .getMemberCount
                                                                      .allPassUserCount
                                                                  ) +
                                                                  "/" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .getMemberCount
                                                                      .basicPassUserCount
                                                                  ) +
                                                                  "/" +
                                                                  _vm._s(
                                                                    _vm.labResultCount
                                                                  ) +
                                                                  "/" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .getMemberCount
                                                                      .attendanceUserCount
                                                                  ) +
                                                                  "/" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .getMemberCount
                                                                      .connectionUserCount
                                                                  ) +
                                                                  "/" +
                                                                  _vm._s(
                                                                    _vm
                                                                      .getMemberCount
                                                                      .usersCount
                                                                  ) +
                                                                  ")\n                                "
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _vm.getMemberCount
                                              .basicPassUserCount == 0
                                              ? _c("span", [
                                                  _vm._v(
                                                    " 수료한사람수/제출한사람수/출석한사람수/접속한사람수/전체사람수 "
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    " 모두성공한사람수/수료한사람수/제출한사람수/출석한사람수/접속한사람수/전체사람수 "
                                                  ),
                                                ]),
                                          ]
                                        ),
                                        _vm.viewMode == "screen"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "5px",
                                                },
                                              },
                                              [
                                                _vm.isReloadingUserImage == true
                                                  ? _c(
                                                      VIcon,
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-spin mdi-refresh"
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      VIcon,
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.reloadUserImages()
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-refresh")]
                                                    ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            style: !_vm.openWideScreen
                                              ? ""
                                              : "display:none",
                                          },
                                          [
                                            _vm.avatarSize == "s"
                                              ? _c(
                                                  VIcon,
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeAvatarSize(
                                                          "isNavBar"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-account-box-outline"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.avatarSize == "m"
                                              ? _c(
                                                  VIcon,
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeAvatarSize(
                                                          "isNavBar"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("mdi-grid")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        Object.keys(_vm.classInfo.groupedUsers)
                                          .length == 1
                                          ? _c(
                                              "div",
                                              {
                                                style: !_vm.openWideScreen
                                                  ? "display:none"
                                                  : "",
                                              },
                                              [
                                                _vm.avatarSize == "m"
                                                  ? _c(
                                                      VIcon,
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeAvatarSize()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-account-box-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.avatarSize == "s"
                                                  ? _c(
                                                      VIcon,
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeAvatarSize()
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-grid-large")]
                                                    )
                                                  : _vm._e(),
                                                _vm.avatarSize == "l"
                                                  ? _c(
                                                      VIcon,
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeAvatarSize()
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-grid")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                style: !_vm.openWideScreen
                                                  ? "display:none"
                                                  : "",
                                              },
                                              [
                                                _vm.avatarSize == "s"
                                                  ? _c(
                                                      VIcon,
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "5px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeAvatarSize(
                                                              "isNavBar"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-account-box-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.avatarSize == "m"
                                                  ? _c(
                                                      VIcon,
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "5px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeAvatarSize(
                                                              "isNavBar"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("mdi-grid")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                        _vm.showLoading
                                          ? _c(VProgressCircular, {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                indeterminate: "",
                                                size: "20",
                                                color: "primary",
                                              },
                                            })
                                          : _vm._e(),
                                        _c(VSpacer),
                                        _vm.isAdmin &&
                                        _vm.openWideScreen &&
                                        Object.keys(_vm.classInfo.groupedUsers)
                                          .length != 1
                                          ? _c(
                                              VIcon,
                                              {
                                                attrs: { text: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteGroup(
                                                      groupIdx,
                                                      key
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("mdi-close")]
                                            )
                                          : _vm._e(),
                                        _vm.isAdmin
                                          ? _c(VCheckbox, {
                                              staticStyle: { width: "200px" },
                                              attrs: { label: `이름 숨기기` },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.hideStudentName()
                                                },
                                              },
                                              model: {
                                                value: _vm.checkbox,
                                                callback: function ($$v) {
                                                  _vm.checkbox = $$v
                                                },
                                                expression: "checkbox",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                _c(
                                  VCardText,
                                  {
                                    staticStyle: {
                                      "max-height": "100%",
                                      padding: "10px",
                                    },
                                    attrs: { center: "" },
                                  },
                                  [
                                    _vm.isAdmin
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "draggable",
                                              {
                                                staticClass: "row",
                                                attrs: {
                                                  list: _vm.filteredUserList,
                                                  tag: "div",
                                                  align: "center",
                                                  group: "a",
                                                },
                                              },
                                              _vm._l(
                                                _vm.filteredUserList,
                                                function (user) {
                                                  return _vm.userResults[
                                                    user.email
                                                  ]
                                                    ? _c(
                                                        VCol,
                                                        {
                                                          key: user.email,
                                                          staticStyle: {
                                                            "min-height":
                                                              "100px",
                                                            "border-radius":
                                                              "10px",
                                                          },
                                                          style:
                                                            _vm.selectedUser
                                                              ? _vm.selectedUser
                                                                  .email !=
                                                                user.email
                                                                ? "opacity:0.4"
                                                                : "background-color:#E0ECF8"
                                                              : "",
                                                          attrs: {
                                                            id: "user-mouseover",
                                                            align: "center",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectUser(
                                                                user
                                                              )
                                                            },
                                                            mouseenter:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.mouseInUser(
                                                                  user
                                                                )
                                                              },
                                                            mouseleave:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.mouseOutUser(
                                                                  user
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              style:
                                                                _vm.avatarSize ==
                                                                "s"
                                                                  ? "min-width:100px"
                                                                  : _vm.avatarSize ==
                                                                    "m"
                                                                  ? "min-width:300px"
                                                                  : _vm.avatarSize ==
                                                                    "l"
                                                                  ? "min-width:600px"
                                                                  : "",
                                                            },
                                                            [
                                                              _vm.viewMode ==
                                                              "avatar"
                                                                ? _c(
                                                                    VBadge,
                                                                    {
                                                                      key: _vm.keyForUserResultStatus,
                                                                      class:
                                                                        !_vm.hideName
                                                                          ? !_vm
                                                                              .userLabResult[
                                                                              user
                                                                                .email
                                                                            ]
                                                                            ? _vm
                                                                                .userMessages[
                                                                                user
                                                                                  .email
                                                                              ]
                                                                              ? _vm
                                                                                  .userMessages[
                                                                                  user
                                                                                    .email
                                                                                ] ==
                                                                                "헬프미"
                                                                                ? "balloon_red"
                                                                                : _vm
                                                                                    .userMessages[
                                                                                    user
                                                                                      .email
                                                                                  ] ==
                                                                                  "chat"
                                                                                ? _vm
                                                                                    .userMessagesStatus[
                                                                                    user
                                                                                      .email
                                                                                  ] ==
                                                                                  "new"
                                                                                  ? "balloon_blue"
                                                                                  : !_vm
                                                                                      .userMessagesChat[
                                                                                      user
                                                                                        .email
                                                                                    ]
                                                                                  ? ""
                                                                                  : "balloon_grey"
                                                                                : _vm
                                                                                    .userMessages[
                                                                                    user
                                                                                      .email
                                                                                  ] ==
                                                                                  "picture"
                                                                                ? ""
                                                                                : "balloon_grey"
                                                                              : ""
                                                                            : ""
                                                                          : "",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-top":
                                                                            "10px",
                                                                          "margin-bottom":
                                                                            "-10px",
                                                                        },
                                                                      attrs: {
                                                                        color:
                                                                          !_vm.hideName
                                                                            ? !_vm
                                                                                .userLabResult[
                                                                                user
                                                                                  .email
                                                                              ]
                                                                              ? _vm
                                                                                  .userMessages[
                                                                                  user
                                                                                    .email
                                                                                ]
                                                                                ? _vm
                                                                                    .userMessages[
                                                                                    user
                                                                                      .email
                                                                                  ] ==
                                                                                  "헬프미"
                                                                                  ? "red"
                                                                                  : _vm
                                                                                      .userMessages[
                                                                                      user
                                                                                        .email
                                                                                    ] ==
                                                                                    "chat"
                                                                                  ? _vm
                                                                                      .userMessagesStatus[
                                                                                      user
                                                                                        .email
                                                                                    ] ==
                                                                                    "new"
                                                                                    ? "blue"
                                                                                    : !_vm
                                                                                        .userMessagesChat[
                                                                                        user
                                                                                          .email
                                                                                      ]
                                                                                    ? ""
                                                                                    : "grey"
                                                                                  : _vm
                                                                                      .userMessages[
                                                                                      user
                                                                                        .email
                                                                                    ] ==
                                                                                    "picture"
                                                                                  ? ""
                                                                                  : "grey"
                                                                                : ""
                                                                              : ""
                                                                            : "",
                                                                        right:
                                                                          "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            _vm.hideName ==
                                                                            false
                                                                              ? {
                                                                                  key: "badge",
                                                                                  fn: function () {
                                                                                    return [
                                                                                      _vm
                                                                                        .userMessages[
                                                                                        user
                                                                                          .email
                                                                                      ] ==
                                                                                        "picture" &&
                                                                                      !_vm
                                                                                        .userLabResult[
                                                                                        user
                                                                                          .email
                                                                                      ]
                                                                                        ? _c(
                                                                                            VIcon,
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "22px",
                                                                                                  "margin-top":
                                                                                                    "8px",
                                                                                                  "margin-left":
                                                                                                    "-4px",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    _vm
                                                                                                      .userMessagesStatus[
                                                                                                      user
                                                                                                        .email
                                                                                                    ] ==
                                                                                                    "new"
                                                                                                      ? "blue"
                                                                                                      : "grey",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " \n                                                mdi-message-image\n                                            "
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm
                                                                                        .userMessages[
                                                                                        user
                                                                                          .email
                                                                                      ] !=
                                                                                        "chat" &&
                                                                                      _vm
                                                                                        .userMessages[
                                                                                        user
                                                                                          .email
                                                                                      ] !=
                                                                                        "picture" &&
                                                                                      !_vm
                                                                                        .userLabResult[
                                                                                        user
                                                                                          .email
                                                                                      ]
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  slot: "badge",
                                                                                                },
                                                                                              slot: "badge",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .userMessages[
                                                                                                        user
                                                                                                          .email
                                                                                                      ]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm
                                                                                        .userMessages[
                                                                                        user
                                                                                          .email
                                                                                      ] ==
                                                                                        "chat" &&
                                                                                      _vm
                                                                                        .userMessagesChat[
                                                                                        user
                                                                                          .email
                                                                                      ] &&
                                                                                      !_vm
                                                                                        .userLabResult[
                                                                                        user
                                                                                          .email
                                                                                      ]
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  slot: "badge",
                                                                                                },
                                                                                              slot: "badge",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "b",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .userMessagesChat[
                                                                                                        user
                                                                                                          .email
                                                                                                      ]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm
                                                                                        .userMessages[
                                                                                        user
                                                                                          .email
                                                                                      ] ==
                                                                                        "chat" &&
                                                                                      !_vm
                                                                                        .userMessagesChat[
                                                                                        user
                                                                                          .email
                                                                                      ] &&
                                                                                      !_vm
                                                                                        .userLabResult[
                                                                                        user
                                                                                          .email
                                                                                      ]
                                                                                        ? _c(
                                                                                            VIcon,
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "22px",
                                                                                                  "margin-top":
                                                                                                    "8px",
                                                                                                  "margin-left":
                                                                                                    "-4px",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    _vm
                                                                                                      .userMessagesStatus[
                                                                                                      user
                                                                                                        .email
                                                                                                    ] ==
                                                                                                    "new"
                                                                                                      ? "blue"
                                                                                                      : "grey",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " \n                                                mdi-message-processing\n                                            "
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  },
                                                                                  proxy: true,
                                                                                }
                                                                              : null,
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .userLabResult[
                                                                        user
                                                                          .email
                                                                      ]
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              style:
                                                                                _vm
                                                                                  .userLabResult[
                                                                                  user
                                                                                    .email
                                                                                ]
                                                                                  ? _vm
                                                                                      .userLabResult[
                                                                                      user
                                                                                        .email
                                                                                    ]
                                                                                      .status ==
                                                                                    "new"
                                                                                    ? "border:3px solid #44bd32"
                                                                                    : ""
                                                                                  : "",
                                                                            },
                                                                            [
                                                                              _vm.userRanking
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          position:
                                                                                            "absolute",
                                                                                          right:
                                                                                            "1px",
                                                                                          top: "1px",
                                                                                          "font-weight":
                                                                                            "bold",
                                                                                        },
                                                                                      style:
                                                                                        _vm
                                                                                          .labInfo
                                                                                          .tool ==
                                                                                        "ide"
                                                                                          ? "color:white"
                                                                                          : "",
                                                                                    },
                                                                                    [
                                                                                      _vm
                                                                                        .userRanking[
                                                                                        user
                                                                                          .email
                                                                                      ] >=
                                                                                      0
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "15px",
                                                                                                  "font-weight":
                                                                                                    "500",
                                                                                                  "background-color":
                                                                                                    "white",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .userRanking[
                                                                                                    user
                                                                                                      .email
                                                                                                  ] +
                                                                                                    1
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.isUserActive &&
                                                                              _vm
                                                                                .isUserActive[
                                                                                user
                                                                                  .email
                                                                              ]
                                                                                ? _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm
                                                                                        .isUserActive[
                                                                                        user
                                                                                          .email
                                                                                      ] ==
                                                                                      "true"
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  position:
                                                                                                    "absolute",
                                                                                                  left: "8%",
                                                                                                  bottom:
                                                                                                    "5%",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "ticontainer",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "tiblock",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .userLabResult[
                                                                                user
                                                                                  .email
                                                                              ]
                                                                                .labResult !=
                                                                                "data:," &&
                                                                              _vm.openWideScreen
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.viewImageInLab(
                                                                                              user.email
                                                                                            ),
                                                                                              (_vm.notSelectUser = true)
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              width:
                                                                                                "100%",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              src: _vm
                                                                                                .userLabResult[
                                                                                                user
                                                                                                  .email
                                                                                              ]
                                                                                                .labResult,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm
                                                                                    .userLabResult[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                    .labResult !=
                                                                                    "data:," &&
                                                                                  !_vm.openWideScreen
                                                                                ? _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          width:
                                                                                            "100%",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          src: _vm
                                                                                            .userLabResult[
                                                                                            user
                                                                                              .email
                                                                                          ]
                                                                                            .labResult,
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.viewImageInLab(
                                                                                              user.email
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  )
                                                                                : _vm
                                                                                    .userLabResult[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                    .labResult ==
                                                                                  "data:,"
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      style:
                                                                                        _vm.avatarSize ==
                                                                                        "s"
                                                                                          ? "min-width:100px; min-height:100px"
                                                                                          : _vm.avatarSize ==
                                                                                            "m"
                                                                                          ? "min-width:300px; min-height:300px"
                                                                                          : _vm.avatarSize ==
                                                                                            "l"
                                                                                          ? "min-width:600px; min-height:600px"
                                                                                          : "",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            VIcon,
                                                                                            {
                                                                                              style:
                                                                                                _vm.avatarSize ==
                                                                                                "s"
                                                                                                  ? "margin-top:30%"
                                                                                                  : _vm.avatarSize ==
                                                                                                    "m"
                                                                                                  ? "margin-top:40%"
                                                                                                  : _vm.avatarSize ==
                                                                                                    "l"
                                                                                                  ? "margin-top:50%"
                                                                                                  : "",
                                                                                              attrs:
                                                                                                {
                                                                                                  "x-large":
                                                                                                    "",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-alert-outline"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              user.photoURL ==
                                                                              undefined
                                                                                ? _c(
                                                                                    VIcon,
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0",
                                                                                      staticStyle:
                                                                                        {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          "z-index":
                                                                                            "5",
                                                                                          position:
                                                                                            "absolute",
                                                                                          left: "1%",
                                                                                          bottom:
                                                                                            "5%",
                                                                                        },
                                                                                      style:
                                                                                        _vm.avatarSize ==
                                                                                        "l"
                                                                                          ? "font-size:48px;"
                                                                                          : _vm.avatarSize ==
                                                                                            "m"
                                                                                          ? "font-size:36px;"
                                                                                          : _vm.avatarSize ==
                                                                                            "s"
                                                                                          ? "font-size:24px;"
                                                                                          : "",
                                                                                      attrs:
                                                                                        {
                                                                                          "x-large":
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.selectUser(
                                                                                              user
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                mdi-account-circle\n                                            "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    VAvatar,
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0",
                                                                                      staticStyle:
                                                                                        {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          "z-index":
                                                                                            "5",
                                                                                          position:
                                                                                            "absolute",
                                                                                          left: "1%",
                                                                                          bottom:
                                                                                            "4%",
                                                                                        },
                                                                                      style:
                                                                                        _vm.avatarSize ==
                                                                                        "m"
                                                                                          ? "width:36px; height:36px;"
                                                                                          : _vm.avatarSize ==
                                                                                            "s"
                                                                                          ? "width:24px; height:24px;"
                                                                                          : "",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              src: user.photoURL,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-bottom":
                                                                                    "20px",
                                                                                },
                                                                            },
                                                                            [
                                                                              user.photoURL ==
                                                                              undefined
                                                                                ? _c(
                                                                                    VIcon,
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0",
                                                                                      staticStyle:
                                                                                        {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          "z-index":
                                                                                            "5",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          "x-large":
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.selectUser(
                                                                                              user
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                mdi-account-circle\n                                            "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    VAvatar,
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0",
                                                                                      staticStyle:
                                                                                        {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          "z-index":
                                                                                            "5",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              src: user.photoURL,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                              _vm.isUserActive &&
                                                                              _vm
                                                                                .isUserActive[
                                                                                user
                                                                                  .email
                                                                              ]
                                                                                ? _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm
                                                                                        .isUserActive[
                                                                                        user
                                                                                          .email
                                                                                      ] ==
                                                                                      "true"
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  position:
                                                                                                    "absolute",
                                                                                                  "margin-top":
                                                                                                    "7px",
                                                                                                  "margin-left":
                                                                                                    "15px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "ticontainer",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "tiblock",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                    ]
                                                                  )
                                                                : _vm.viewMode !=
                                                                  "avatar"
                                                                ? _c(
                                                                    "ReplayPlayer",
                                                                    {
                                                                      style:
                                                                        _vm.avatarSize ==
                                                                        "s"
                                                                          ? "width:150px"
                                                                          : _vm.avatarSize ==
                                                                            "m"
                                                                          ? "width:300px"
                                                                          : _vm.avatarSize ==
                                                                            "l"
                                                                          ? "width:600px"
                                                                          : "",
                                                                      attrs: {
                                                                        "selected-user":
                                                                          user,
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm.hideName ==
                                                              false
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-top":
                                                                            "-10px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .userScores[
                                                                        user
                                                                          .email
                                                                      ] &&
                                                                      _vm.bestScores.indexOf(
                                                                        _vm
                                                                          .userScores[
                                                                          user
                                                                            .email
                                                                        ].score
                                                                      ) > -1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-top":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                VIcon,
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        [
                                                                                          "green",
                                                                                          "orange",
                                                                                          "silver",
                                                                                          "#CD7F32",
                                                                                        ][
                                                                                          _vm.bestScores.indexOf(
                                                                                            _vm
                                                                                              .userScores[
                                                                                              user
                                                                                                .email
                                                                                            ]
                                                                                              .score
                                                                                          ) +
                                                                                            1
                                                                                        ],
                                                                                      right:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-trophy\n                                            "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "font",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .userResults[
                                                                                  user
                                                                                    .email
                                                                                ] &&
                                                                                (_vm
                                                                                  .userResults[
                                                                                  user
                                                                                    .email
                                                                                ]
                                                                                  .allPassed ||
                                                                                  _vm
                                                                                    .userResults[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                    .labPass)
                                                                                  ? "green"
                                                                                  : "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _c(
                                                                            "b",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  user.userName
                                                                                ) +
                                                                                  " \n                                                "
                                                                              ),
                                                                              _vm
                                                                                .userLabResult[
                                                                                user
                                                                                  .email
                                                                              ]
                                                                                ? _c(
                                                                                    VBadge,
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-left":
                                                                                            "5px",
                                                                                          "margin-bottom":
                                                                                            "-2px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            _vm
                                                                                              .userMessages[
                                                                                              user
                                                                                                .email
                                                                                            ]
                                                                                              ? _vm
                                                                                                  .userMessages[
                                                                                                  user
                                                                                                    .email
                                                                                                ] ==
                                                                                                "헬프미"
                                                                                                ? "red"
                                                                                                : _vm
                                                                                                    .userMessages[
                                                                                                    user
                                                                                                      .email
                                                                                                  ] ==
                                                                                                  "chat"
                                                                                                ? _vm
                                                                                                    .userMessagesStatus[
                                                                                                    user
                                                                                                      .email
                                                                                                  ] ==
                                                                                                  "new"
                                                                                                  ? "blue"
                                                                                                  : !_vm
                                                                                                      .userMessagesChat[
                                                                                                      user
                                                                                                        .email
                                                                                                    ]
                                                                                                  ? ""
                                                                                                  : "grey"
                                                                                                : _vm
                                                                                                    .userMessages[
                                                                                                    user
                                                                                                      .email
                                                                                                  ] ==
                                                                                                  "picture"
                                                                                                ? ""
                                                                                                : "grey"
                                                                                              : "",
                                                                                        },
                                                                                      scopedSlots:
                                                                                        _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key: "badge",
                                                                                              fn: function () {
                                                                                                return [
                                                                                                  _vm
                                                                                                    .userMessages[
                                                                                                    user
                                                                                                      .email
                                                                                                  ] ==
                                                                                                    "picture" &&
                                                                                                  _vm
                                                                                                    .userLabResult[
                                                                                                    user
                                                                                                      .email
                                                                                                  ]
                                                                                                    .labResult
                                                                                                    ? _c(
                                                                                                        VIcon,
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              "font-size":
                                                                                                                "22px",
                                                                                                              "margin-top":
                                                                                                                "-4px",
                                                                                                              "margin-left":
                                                                                                                "-7px",
                                                                                                            },
                                                                                                          attrs:
                                                                                                            {
                                                                                                              color:
                                                                                                                _vm
                                                                                                                  .userMessagesStatus[
                                                                                                                  user
                                                                                                                    .email
                                                                                                                ] ==
                                                                                                                "new"
                                                                                                                  ? "blue"
                                                                                                                  : "grey",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " \n                                                            mdi-image\n                                                        "
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  _vm
                                                                                                    .userMessages[
                                                                                                    user
                                                                                                      .email
                                                                                                  ] !=
                                                                                                    "chat" &&
                                                                                                  _vm
                                                                                                    .userMessages[
                                                                                                    user
                                                                                                      .email
                                                                                                  ] !=
                                                                                                    "picture"
                                                                                                    ? _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              slot: "badge",
                                                                                                            },
                                                                                                          slot: "badge",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "b",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  _vm
                                                                                                                    .userMessages[
                                                                                                                    user
                                                                                                                      .email
                                                                                                                  ]
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  _vm
                                                                                                    .userMessages[
                                                                                                    user
                                                                                                      .email
                                                                                                  ] ==
                                                                                                    "chat" &&
                                                                                                  _vm
                                                                                                    .userMessagesChat[
                                                                                                    user
                                                                                                      .email
                                                                                                  ]
                                                                                                    ? _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              slot: "badge",
                                                                                                            },
                                                                                                          slot: "badge",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "b",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  _vm
                                                                                                                    .userMessagesChat[
                                                                                                                    user
                                                                                                                      .email
                                                                                                                  ]
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  _vm
                                                                                                    .userMessages[
                                                                                                    user
                                                                                                      .email
                                                                                                  ] ==
                                                                                                    "chat" &&
                                                                                                  !_vm
                                                                                                    .userMessagesChat[
                                                                                                    user
                                                                                                      .email
                                                                                                  ] &&
                                                                                                  _vm
                                                                                                    .userLabResult[
                                                                                                    user
                                                                                                      .email
                                                                                                  ]
                                                                                                    .labResult
                                                                                                    ? _c(
                                                                                                        VIcon,
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              "font-size":
                                                                                                                "22px",
                                                                                                              "margin-top":
                                                                                                                "-4px",
                                                                                                              "margin-left":
                                                                                                                "-7px",
                                                                                                            },
                                                                                                          attrs:
                                                                                                            {
                                                                                                              color:
                                                                                                                _vm
                                                                                                                  .userMessagesStatus[
                                                                                                                  user
                                                                                                                    .email
                                                                                                                ] ==
                                                                                                                "new"
                                                                                                                  ? "blue"
                                                                                                                  : "grey",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " \n                                                            mdi-dots-horizontal-circle\n                                                        "
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                ]
                                                                                              },
                                                                                              proxy: true,
                                                                                            },
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        ),
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .userResults[
                                                                                      user
                                                                                        .email
                                                                                    ]
                                                                                      ? _vm
                                                                                          .userResults[
                                                                                          user
                                                                                            .email
                                                                                        ]
                                                                                          .passMessage
                                                                                      : ""
                                                                                  )
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "font-size":
                                                                                    "5px",
                                                                                },
                                                                              style:
                                                                                _vm
                                                                                  .ideStatus[
                                                                                  user.email.replace(
                                                                                    "@",
                                                                                    "_"
                                                                                  )
                                                                                ]
                                                                                  ? _vm
                                                                                      .ideStatus[
                                                                                      user.email.replace(
                                                                                        "@",
                                                                                        "_"
                                                                                      )
                                                                                    ] ==
                                                                                    null
                                                                                    ? {
                                                                                        color:
                                                                                          "red",
                                                                                      }
                                                                                    : {
                                                                                        color:
                                                                                          "green",
                                                                                      }
                                                                                  : {
                                                                                      color:
                                                                                        "grey",
                                                                                    },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " ●"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm
                                                                            .userResults[
                                                                            user
                                                                              .email
                                                                          ] ==
                                                                          null
                                                                            ? _c(
                                                                                VSkeletonLoader,
                                                                                {
                                                                                  ref: "skeleton",
                                                                                  refInFor: true,
                                                                                  attrs:
                                                                                    {
                                                                                      type: "sentences",
                                                                                      "max-height":
                                                                                        "10",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm.showingScores &&
                                                                      _vm
                                                                        .newUserScores[
                                                                        user
                                                                          .email
                                                                      ] &&
                                                                      _vm
                                                                        .newUserScores[
                                                                        user
                                                                          .email
                                                                      ].score >
                                                                        0
                                                                        ? _c(
                                                                            VChip,
                                                                            {
                                                                              staticClass:
                                                                                "ma-0",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "green",
                                                                                  "text-color":
                                                                                    "white",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                            " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .newUserScores[
                                                                                      user
                                                                                        .email
                                                                                    ]
                                                                                      .score
                                                                                      ? Math.round(
                                                                                          _vm
                                                                                            .newUserScores[
                                                                                            user
                                                                                              .email
                                                                                          ]
                                                                                            .score
                                                                                        )
                                                                                      : 0
                                                                                  ) +
                                                                                  "\n                                        "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              VRow,
                                              { attrs: { align: "center" } },
                                              _vm._l(
                                                _vm.filteredUserList,
                                                function (
                                                  user,
                                                  userKey,
                                                  userIdx
                                                ) {
                                                  return _vm.userResults[
                                                    user.email
                                                  ]
                                                    ? _c(
                                                        VCol,
                                                        {
                                                          key: user.email,
                                                          staticStyle: {
                                                            "min-height":
                                                              "100px",
                                                            "border-radius":
                                                              "10px",
                                                          },
                                                          style:
                                                            _vm.selectedUser
                                                              ? _vm.selectedUser
                                                                  .email !=
                                                                user.email
                                                                ? "opacity:0.4"
                                                                : "background-color:#E0ECF8"
                                                              : "",
                                                          attrs: {
                                                            align: "center",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.selectUser(
                                                                user
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              style:
                                                                _vm.avatarSize ==
                                                                "s"
                                                                  ? "min-width:100px"
                                                                  : _vm.avatarSize ==
                                                                    "m"
                                                                  ? "min-width:300px"
                                                                  : _vm.avatarSize ==
                                                                    "l"
                                                                  ? "min-width:600px"
                                                                  : "",
                                                            },
                                                            [
                                                              _vm.viewMode ==
                                                              "avatar"
                                                                ? _c(
                                                                    VBadge,
                                                                    {
                                                                      key: _vm.keyForUserResultStatus,
                                                                      class:
                                                                        !_vm
                                                                          .userLabResult[
                                                                          user
                                                                            .email
                                                                        ]
                                                                          ? _vm
                                                                              .userMessages[
                                                                              user
                                                                                .email
                                                                            ]
                                                                            ? _vm
                                                                                .userMessages[
                                                                                user
                                                                                  .email
                                                                              ] ==
                                                                              "헬프미"
                                                                              ? "balloon_red"
                                                                              : _vm
                                                                                  .userMessages[
                                                                                  user
                                                                                    .email
                                                                                ] ==
                                                                                "chat"
                                                                              ? _vm
                                                                                  .userMessagesStatus[
                                                                                  user
                                                                                    .email
                                                                                ] ==
                                                                                "new"
                                                                                ? "balloon_blue"
                                                                                : !_vm
                                                                                    .userMessagesChat[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                ? ""
                                                                                : "balloon_grey"
                                                                              : _vm
                                                                                  .userMessages[
                                                                                  user
                                                                                    .email
                                                                                ] ==
                                                                                "picture"
                                                                              ? ""
                                                                              : "balloon_grey"
                                                                            : ""
                                                                          : "",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-top":
                                                                            "10px",
                                                                          "margin-bottom":
                                                                            "-10px",
                                                                        },
                                                                      attrs: {
                                                                        color:
                                                                          !_vm
                                                                            .userLabResult[
                                                                            user
                                                                              .email
                                                                          ]
                                                                            ? _vm
                                                                                .userMessages[
                                                                                user
                                                                                  .email
                                                                              ] ==
                                                                              "헬프미"
                                                                              ? "red"
                                                                              : _vm
                                                                                  .userMessages[
                                                                                  user
                                                                                    .email
                                                                                ] ==
                                                                                "chat"
                                                                              ? _vm
                                                                                  .userMessagesStatus[
                                                                                  user
                                                                                    .email
                                                                                ] ==
                                                                                "new"
                                                                                ? "blue"
                                                                                : ""
                                                                              : ""
                                                                            : "",
                                                                        right:
                                                                          "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "badge",
                                                                              fn: function () {
                                                                                return [
                                                                                  _vm
                                                                                    .userMessages[
                                                                                    user
                                                                                      .email
                                                                                  ] ==
                                                                                    "picture" &&
                                                                                  !_vm
                                                                                    .userLabResult[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                    ? _c(
                                                                                        VIcon,
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "font-size":
                                                                                                "22px",
                                                                                              "margin-top":
                                                                                                "8px",
                                                                                              "margin-left":
                                                                                                "-4px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                _vm
                                                                                                  .userMessagesStatus[
                                                                                                  user
                                                                                                    .email
                                                                                                ] ==
                                                                                                "new"
                                                                                                  ? "blue"
                                                                                                  : "grey",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " \n                                                mdi-message-image\n                                            "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm
                                                                                    .userMessages[
                                                                                    user
                                                                                      .email
                                                                                  ] !=
                                                                                    "chat" &&
                                                                                  _vm
                                                                                    .userMessages[
                                                                                    user
                                                                                      .email
                                                                                  ] !=
                                                                                    "picture" &&
                                                                                  !_vm
                                                                                    .userLabResult[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              slot: "badge",
                                                                                            },
                                                                                          slot: "badge",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .userMessages[
                                                                                                    user
                                                                                                      .email
                                                                                                  ]
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm
                                                                                    .userMessages[
                                                                                    user
                                                                                      .email
                                                                                  ] ==
                                                                                    "chat" &&
                                                                                  _vm
                                                                                    .userMessagesChat[
                                                                                    user
                                                                                      .email
                                                                                  ] &&
                                                                                  !_vm
                                                                                    .userLabResult[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              slot: "badge",
                                                                                            },
                                                                                          slot: "badge",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "b",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .userMessagesChat[
                                                                                                    user
                                                                                                      .email
                                                                                                  ]
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm
                                                                                    .userMessages[
                                                                                    user
                                                                                      .email
                                                                                  ] ==
                                                                                    "chat" &&
                                                                                  !_vm
                                                                                    .userMessagesChat[
                                                                                    user
                                                                                      .email
                                                                                  ] &&
                                                                                  !_vm
                                                                                    .userLabResult[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                    ? _c(
                                                                                        VIcon,
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "font-size":
                                                                                                "22px",
                                                                                              "margin-top":
                                                                                                "8px",
                                                                                              "margin-left":
                                                                                                "-4px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                _vm
                                                                                                  .userMessagesStatus[
                                                                                                  user
                                                                                                    .email
                                                                                                ] ==
                                                                                                "new"
                                                                                                  ? "blue"
                                                                                                  : "grey",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " \n                                                mdi-message-processing\n                                            "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .userLabResult[
                                                                        user
                                                                          .email
                                                                      ]
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _vm.userRanking
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          position:
                                                                                            "absolute",
                                                                                          right:
                                                                                            "5%",
                                                                                          top: "5%",
                                                                                          "font-weight":
                                                                                            "bold",
                                                                                          "text-shadow":
                                                                                            "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
                                                                                        },
                                                                                      style:
                                                                                        _vm
                                                                                          .labInfo
                                                                                          .tool ==
                                                                                        "ide"
                                                                                          ? "color:white"
                                                                                          : "",
                                                                                    },
                                                                                    [
                                                                                      _vm
                                                                                        .userRanking[
                                                                                        user
                                                                                          .email
                                                                                      ] >=
                                                                                      0
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "15px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "#" +
                                                                                                  _vm._s(
                                                                                                    _vm
                                                                                                      .userRanking[
                                                                                                      user
                                                                                                        .email
                                                                                                    ] +
                                                                                                      1
                                                                                                  )
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.isUserActive &&
                                                                              _vm
                                                                                .isUserActive[
                                                                                user
                                                                                  .email
                                                                              ]
                                                                                ? _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm
                                                                                        .isUserActive[
                                                                                        user
                                                                                          .email
                                                                                      ] ==
                                                                                      "true"
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  position:
                                                                                                    "absolute",
                                                                                                  left: "8%",
                                                                                                  bottom:
                                                                                                    "5%",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "ticontainer",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "tiblock",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .userLabResult[
                                                                                user
                                                                                  .email
                                                                              ]
                                                                                .labResult !=
                                                                                "data:," &&
                                                                              _vm.openWideScreen
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.viewImageInLab(
                                                                                              user.email
                                                                                            ),
                                                                                              (_vm.notSelectUser = true)
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              width:
                                                                                                "100%",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              src: _vm
                                                                                                .userLabResult[
                                                                                                user
                                                                                                  .email
                                                                                              ]
                                                                                                .labResult,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm
                                                                                    .userLabResult[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                    .labResult !=
                                                                                    "data:," &&
                                                                                  !_vm.openWideScreen
                                                                                ? _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          width:
                                                                                            "100%",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          src: _vm
                                                                                            .userLabResult[
                                                                                            user
                                                                                              .email
                                                                                          ]
                                                                                            .labResult,
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.viewImageInLab(
                                                                                              user.email
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  )
                                                                                : _vm
                                                                                    .userLabResult[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                    .labResult ==
                                                                                  "data:,"
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      style:
                                                                                        _vm.avatarSize ==
                                                                                        "s"
                                                                                          ? "min-width:100px; min-height:100px"
                                                                                          : _vm.avatarSize ==
                                                                                            "m"
                                                                                          ? "min-width:300px; min-height:300px"
                                                                                          : _vm.avatarSize ==
                                                                                            "l"
                                                                                          ? "min-width:600px; min-height:600px"
                                                                                          : "",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            VIcon,
                                                                                            {
                                                                                              style:
                                                                                                _vm.avatarSize ==
                                                                                                "s"
                                                                                                  ? "margin-top:30%"
                                                                                                  : _vm.avatarSize ==
                                                                                                    "m"
                                                                                                  ? "margin-top:40%"
                                                                                                  : _vm.avatarSize ==
                                                                                                    "l"
                                                                                                  ? "margin-top:50%"
                                                                                                  : "",
                                                                                              attrs:
                                                                                                {
                                                                                                  "x-large":
                                                                                                    "",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-alert-outline"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              user.photoURL ==
                                                                              undefined
                                                                                ? _c(
                                                                                    VIcon,
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0",
                                                                                      staticStyle:
                                                                                        {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          "z-index":
                                                                                            "5",
                                                                                          position:
                                                                                            "absolute",
                                                                                          right:
                                                                                            "1%",
                                                                                          bottom:
                                                                                            "5%",
                                                                                        },
                                                                                      style:
                                                                                        _vm.avatarSize ==
                                                                                        "l"
                                                                                          ? "font-size:48px;"
                                                                                          : _vm.avatarSize ==
                                                                                            "m"
                                                                                          ? "font-size:36px;"
                                                                                          : _vm.avatarSize ==
                                                                                            "s"
                                                                                          ? "font-size:24px;"
                                                                                          : "",
                                                                                      attrs:
                                                                                        {
                                                                                          "x-large":
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.selectUser(
                                                                                              user
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                mdi-account-circle\n                                            "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    VAvatar,
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0",
                                                                                      staticStyle:
                                                                                        {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          "z-index":
                                                                                            "5",
                                                                                          position:
                                                                                            "absolute",
                                                                                          right:
                                                                                            "1%",
                                                                                          bottom:
                                                                                            "5%",
                                                                                        },
                                                                                      style:
                                                                                        _vm.avatarSize ==
                                                                                        "m"
                                                                                          ? "width:36px; height:36px;"
                                                                                          : _vm.avatarSize ==
                                                                                            "s"
                                                                                          ? "width:24px; height:24px;"
                                                                                          : "",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              src: user.photoURL,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-bottom":
                                                                                    "20px",
                                                                                },
                                                                            },
                                                                            [
                                                                              user.photoURL ==
                                                                              undefined
                                                                                ? _c(
                                                                                    VIcon,
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0",
                                                                                      staticStyle:
                                                                                        {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          "z-index":
                                                                                            "5",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          "x-large":
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.selectUser(
                                                                                              user
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                mdi-account-circle\n                                            "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    VAvatar,
                                                                                    {
                                                                                      staticClass:
                                                                                        "ma-0",
                                                                                      staticStyle:
                                                                                        {
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          "z-index":
                                                                                            "5",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              src: user.photoURL,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                              _vm.isUserActive &&
                                                                              _vm
                                                                                .isUserActive[
                                                                                user
                                                                                  .email
                                                                              ]
                                                                                ? _c(
                                                                                    "div",
                                                                                    [
                                                                                      _vm
                                                                                        .isUserActive[
                                                                                        user
                                                                                          .email
                                                                                      ] ==
                                                                                      "true"
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  position:
                                                                                                    "absolute",
                                                                                                  "margin-top":
                                                                                                    "7px",
                                                                                                  "margin-left":
                                                                                                    "15px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "ticontainer",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "tiblock",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "tidot",
                                                                                                        }
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                    ]
                                                                  )
                                                                : _vm.viewMode !=
                                                                  "avatar"
                                                                ? _c(
                                                                    "ReplayPlayer",
                                                                    {
                                                                      style:
                                                                        _vm.avatarSize ==
                                                                        "s"
                                                                          ? "width:150px"
                                                                          : _vm.avatarSize ==
                                                                            "m"
                                                                          ? "width:300px"
                                                                          : _vm.avatarSize ==
                                                                            "l"
                                                                          ? "width:600px"
                                                                          : "",
                                                                      attrs: {
                                                                        "selected-user":
                                                                          user,
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _vm
                                                                    .userScores[
                                                                    user.email
                                                                  ] &&
                                                                  _vm.bestScores.indexOf(
                                                                    _vm
                                                                      .userScores[
                                                                      user.email
                                                                    ].score
                                                                  ) > -1
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "15px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VIcon,
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    [
                                                                                      "green",
                                                                                      "orange",
                                                                                      "silver",
                                                                                      "#CD7F32",
                                                                                    ][
                                                                                      _vm.bestScores.indexOf(
                                                                                        _vm
                                                                                          .userScores[
                                                                                          user
                                                                                            .email
                                                                                        ]
                                                                                          .score
                                                                                      ) +
                                                                                        1
                                                                                    ],
                                                                                  right:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-trophy\n                                                "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "font",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .userResults[
                                                                            user
                                                                              .email
                                                                          ] &&
                                                                          (_vm
                                                                            .userResults[
                                                                            user
                                                                              .email
                                                                          ]
                                                                            .allPassed ||
                                                                            _vm
                                                                              .userResults[
                                                                              user
                                                                                .email
                                                                            ]
                                                                              .labPass)
                                                                            ? "green"
                                                                            : "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("br"),
                                                                      _c(
                                                                        "b",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              user.userName
                                                                            ) +
                                                                              " \n                                                "
                                                                          ),
                                                                          _vm
                                                                            .userLabResult[
                                                                            user
                                                                              .email
                                                                          ]
                                                                            ? _c(
                                                                                VBadge,
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "margin-left":
                                                                                        "5px",
                                                                                      "margin-bottom":
                                                                                        "-2px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        _vm
                                                                                          .userMessages[
                                                                                          user
                                                                                            .email
                                                                                        ]
                                                                                          ? _vm
                                                                                              .userMessages[
                                                                                              user
                                                                                                .email
                                                                                            ] ==
                                                                                            "헬프미"
                                                                                            ? "red"
                                                                                            : _vm
                                                                                                .userMessages[
                                                                                                user
                                                                                                  .email
                                                                                              ] ==
                                                                                              "chat"
                                                                                            ? _vm
                                                                                                .userMessagesStatus[
                                                                                                user
                                                                                                  .email
                                                                                              ] ==
                                                                                              "new"
                                                                                              ? "blue"
                                                                                              : !_vm
                                                                                                  .userMessagesChat[
                                                                                                  user
                                                                                                    .email
                                                                                                ]
                                                                                              ? ""
                                                                                              : "grey"
                                                                                            : _vm
                                                                                                .userMessages[
                                                                                                user
                                                                                                  .email
                                                                                              ] ==
                                                                                              "picture"
                                                                                            ? ""
                                                                                            : "grey"
                                                                                          : "",
                                                                                    },
                                                                                  scopedSlots:
                                                                                    _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key: "badge",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _vm
                                                                                                .userMessages[
                                                                                                user
                                                                                                  .email
                                                                                              ] ==
                                                                                                "picture" &&
                                                                                              _vm
                                                                                                .userLabResult[
                                                                                                user
                                                                                                  .email
                                                                                              ]
                                                                                                .labResult
                                                                                                ? _c(
                                                                                                    VIcon,
                                                                                                    {
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "font-size":
                                                                                                            "22px",
                                                                                                          "margin-top":
                                                                                                            "-4px",
                                                                                                          "margin-left":
                                                                                                            "-7px",
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          color:
                                                                                                            _vm
                                                                                                              .userMessagesStatus[
                                                                                                              user
                                                                                                                .email
                                                                                                            ] ==
                                                                                                            "new"
                                                                                                              ? "blue"
                                                                                                              : "grey",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " \n                                                            mdi-image\n                                                        "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm
                                                                                                .userMessages[
                                                                                                user
                                                                                                  .email
                                                                                              ] !=
                                                                                                "chat" &&
                                                                                              _vm
                                                                                                .userMessages[
                                                                                                user
                                                                                                  .email
                                                                                              ] !=
                                                                                                "picture"
                                                                                                ? _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          slot: "badge",
                                                                                                        },
                                                                                                      slot: "badge",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "b",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              _vm
                                                                                                                .userMessages[
                                                                                                                user
                                                                                                                  .email
                                                                                                              ]
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm
                                                                                                .userMessages[
                                                                                                user
                                                                                                  .email
                                                                                              ] ==
                                                                                                "chat" &&
                                                                                              _vm
                                                                                                .userMessagesChat[
                                                                                                user
                                                                                                  .email
                                                                                              ]
                                                                                                ? _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          slot: "badge",
                                                                                                        },
                                                                                                      slot: "badge",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "b",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              _vm
                                                                                                                .userMessagesChat[
                                                                                                                user
                                                                                                                  .email
                                                                                                              ]
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm
                                                                                                .userMessages[
                                                                                                user
                                                                                                  .email
                                                                                              ] ==
                                                                                                "chat" &&
                                                                                              !_vm
                                                                                                .userMessagesChat[
                                                                                                user
                                                                                                  .email
                                                                                              ] &&
                                                                                              _vm
                                                                                                .userLabResult[
                                                                                                user
                                                                                                  .email
                                                                                              ]
                                                                                                .labResult
                                                                                                ? _c(
                                                                                                    VIcon,
                                                                                                    {
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "font-size":
                                                                                                            "22px",
                                                                                                          "margin-top":
                                                                                                            "-4px",
                                                                                                          "margin-left":
                                                                                                            "-7px",
                                                                                                        },
                                                                                                      attrs:
                                                                                                        {
                                                                                                          color:
                                                                                                            _vm
                                                                                                              .userMessagesStatus[
                                                                                                              user
                                                                                                                .email
                                                                                                            ] ==
                                                                                                            "new"
                                                                                                              ? "blue"
                                                                                                              : "grey",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " \n                                                            mdi-dots-horizontal-circle\n                                                        "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .userResults[
                                                                                  user
                                                                                    .email
                                                                                ]
                                                                                  ? _vm
                                                                                      .userResults[
                                                                                      user
                                                                                        .email
                                                                                    ]
                                                                                      .passMessage
                                                                                  : ""
                                                                              )
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm
                                                                        .userResults[
                                                                        user
                                                                          .email
                                                                      ] == null
                                                                        ? _c(
                                                                            VSkeletonLoader,
                                                                            {
                                                                              ref: "skeleton",
                                                                              refInFor: true,
                                                                              attrs:
                                                                                {
                                                                                  type: "sentences",
                                                                                  "max-height":
                                                                                    "10",
                                                                                },
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm.showingScores &&
                                                              _vm.newUserScores[
                                                                user.email
                                                              ] &&
                                                              _vm.newUserScores[
                                                                user.email
                                                              ].score > 0
                                                                ? _c(
                                                                    VChip,
                                                                    {
                                                                      staticClass:
                                                                        "ma-0",
                                                                      attrs: {
                                                                        color:
                                                                          "green",
                                                                        "text-color":
                                                                          "white",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                            " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .newUserScores[
                                                                              user
                                                                                .email
                                                                            ]
                                                                              .score
                                                                              ? Math.round(
                                                                                  _vm
                                                                                    .newUserScores[
                                                                                    user
                                                                                      .email
                                                                                  ]
                                                                                    .score
                                                                                )
                                                                              : "0"
                                                                          ) +
                                                                          "\n                                        "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  _vm.selectedUser
                    ? _c(
                        VNavigationDrawer,
                        {
                          staticStyle: { "z-index": "10" },
                          attrs: {
                            absolute: "",
                            permanent: "",
                            right: "",
                            width: "50%",
                          },
                        },
                        [
                          _c(
                            VTabs,
                            {
                              attrs: { color: "cyan", flat: "" },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _vm._l(_vm.items, function (item) {
                                return _c(VTab, { key: item.tab }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.tab) +
                                      "\n                        "
                                  ),
                                ])
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "padding-top": "12px",
                                    "line-height": "100%",
                                  },
                                },
                                [
                                  _c(
                                    VIcon,
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.unselectUser()
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VTabItem,
                                [
                                  _c(VDivider),
                                  _c(
                                    VIcon,
                                    {
                                      style: { color: _vm.selectedUser.color },
                                      attrs: { "x-large": "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                mdi-account-circle\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.selectedUser.userName) +
                                      " - " +
                                      _vm._s(_vm.selectedUser.email) +
                                      "\n                            "
                                  ),
                                  _vm.isAdmin ||
                                  _vm.selectedUser.email == _vm.myId
                                    ? _c(
                                        VIcon,
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { color: "black", small: "" },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.showChangedUserDialog(
                                                _vm.selectedUser
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                mdi-pencil\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.labInfo.tool == "ide"
                                    ? _c(VSubheader, [
                                        _vm._v(
                                          " Hash Name: labs-" +
                                            _vm._s(_vm.getHashCode())
                                        ),
                                      ])
                                    : _vm.labInfo.tool == "jupyter"
                                    ? _c(VSubheader, [
                                        _vm._v(
                                          " Hash Name: jpt-" +
                                            _vm._s(_vm.getHashCode()) +
                                            "\n                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    VList,
                                    { attrs: { dense: "" } },
                                    [
                                      _c(VSubheader, [
                                        _vm._v(
                                          "\n                                    Checkpoints\n                                "
                                        ),
                                      ]),
                                      _vm._l(
                                        _vm.userResults[_vm.selectedUser.email],
                                        function (item, idx) {
                                          return _c(
                                            VListItem,
                                            { key: idx },
                                            [
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(idx + 1) +
                                                        ". " +
                                                        _vm._s(item.text)
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemAction,
                                                [
                                                  _vm.isAdmin
                                                    ? _c(VCheckbox, {
                                                        attrs: { success: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.sendCheckPoints(
                                                              _vm.selectedUser
                                                                .email,
                                                              item
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value: item.status,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "status",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.status",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  !_vm.isAdmin
                                                    ? _c(VCheckbox, {
                                                        attrs: {
                                                          success: "",
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value: item.status,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "status",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.status",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c(VDivider),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "5px",
                                        "margin-bottom": "20px",
                                      },
                                    },
                                    [
                                      _vm.isAdmin ||
                                      _vm.selectedUser.email == _vm.myId
                                        ? _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                "margin-bottom": "5px",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                to:
                                                  "/courses/" +
                                                  _vm.courseId +
                                                  "/" +
                                                  _vm.classId +
                                                  "/" +
                                                  _vm.labId +
                                                  "/" +
                                                  _vm.selectedUser.email,
                                                small: "",
                                                color: "primary",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "랩실 보기\n                                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      (_vm.isAdmin ||
                                        _vm.selectedUser.email == _vm.myId) &&
                                      _vm.selectedUser.deleteInterval ==
                                        undefined &&
                                      _vm.labInfo.tool == "event-storming"
                                        ? _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                "margin-bottom": "5px",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                color: "error",
                                                target: "_blank",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clearStorming(
                                                    _vm.selectedUser
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "초기화\n                                "
                                              ),
                                            ]
                                          )
                                        : (_vm.isAdmin ||
                                            _vm.selectedUser.email ==
                                              _vm.myId) &&
                                          _vm.selectedUser.deleteInterval ==
                                            undefined
                                        ? _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                "margin-bottom": "5px",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                small: "",
                                                color: "error",
                                                target: "_blank",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clearLabDialog(
                                                    _vm.selectedUser
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "초기화\n                                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isAdmin
                                        ? _c(
                                            VBtn,
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                "margin-bottom": "5px",
                                                "font-weight": "850",
                                              },
                                              attrs: {
                                                color: "green darken-1",
                                                dark: "",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.scoreDialog = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    상금 지급\n                                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(VDivider),
                                      _c(
                                        VRow,
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            VDialog,
                                            {
                                              attrs: { "max-width": "450" },
                                              model: {
                                                value: _vm.scoreDialog,
                                                callback: function ($$v) {
                                                  _vm.scoreDialog = $$v
                                                },
                                                expression: "scoreDialog",
                                              },
                                            },
                                            [
                                              _c(
                                                VCard,
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "5px",
                                                        "margin-right": "5px",
                                                        "margin-bottom": "5px",
                                                      },
                                                      attrs: { align: "right" },
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          attrs: { text: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.scoreDialog = false
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VCardTitle,
                                                    { staticClass: "headline" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.selectedUser
                                                            .userName
                                                        ) +
                                                          "의 보유코인 :\n                                                " +
                                                          _vm._s(
                                                            _vm.userScores[
                                                              _vm.selectedUser
                                                                .email
                                                            ]
                                                              ? Math.round(
                                                                  _vm
                                                                    .userScores[
                                                                    _vm
                                                                      .selectedUser
                                                                      .email
                                                                  ].score
                                                                )
                                                              : 0
                                                          ) +
                                                          "coins\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    VCardText,
                                                    [
                                                      _c(
                                                        VCol,
                                                        [
                                                          _c(VTextField, {
                                                            attrs: {
                                                              type: "number",
                                                              label:
                                                                "상금 코인",
                                                            },
                                                            model: {
                                                              value: _vm.prize,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.prize =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "prize",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        VBtn,
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "260px",
                                                          },
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addIndividualScore()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "지급\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        VBtn,
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "10px",
                                                          },
                                                          attrs: {
                                                            color: "error",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeIndividualScore()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "차감\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VCardActions,
                                                    [_c(VSpacer)],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VTabItem,
                                [
                                  _c("ClassRoomChat", {
                                    attrs: {
                                      "init-user": _vm.connectUserId,
                                      "selected-user": _vm.selectedUser,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    [
                      _c(VSkeletonLoader, {
                        ref: "skeleton",
                        staticStyle: {
                          width: "50%",
                          height: "10%",
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                        },
                        attrs: { indeterminate: "", type: "image" },
                      }),
                      _c(VSkeletonLoader, {
                        ref: "skeleton",
                        staticStyle: {
                          width: "25%",
                          height: "5%",
                          position: "absolute",
                          top: "13%",
                          left: "10px",
                        },
                        attrs: { indeterminate: "", type: "image" },
                      }),
                      _c(VSkeletonLoader, {
                        ref: "skeleton",
                        staticStyle: {
                          width: "99%",
                          height: "67%",
                          position: "absolute",
                          top: "19%",
                          left: "10px",
                        },
                        attrs: { indeterminate: "", type: "image" },
                      }),
                      _c(VSkeletonLoader, {
                        ref: "skeleton",
                        staticStyle: {
                          width: "4%",
                          height: "8%",
                          position: "absolute",
                          top: "90%",
                          left: "10px",
                        },
                        attrs: { indeterminate: "", type: "image" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm.isAdmin
        ? _c("question", {
            ref: "question",
            attrs: { "lab-info": _vm.labInfo, "class-info": _vm.classInfo },
          })
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: { width: "1000" },
          on: {
            "click:outside": function ($event) {
              _vm.removeChatImg(), (_vm.notSelectUser = false)
            },
          },
          model: {
            value: _vm.chatImgDialog,
            callback: function ($$v) {
              _vm.chatImgDialog = $$v
            },
            expression: "chatImgDialog",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { display: "-webkit-box" },
              on: {
                mouseleave: function ($event) {
                  ;(_vm.chatImgDialog = false), (_vm.notSelectUser = false)
                },
              },
            },
            [
              _c(VImg, {
                staticStyle: { "background-color": "white" },
                attrs: { src: _vm.chatImage },
              }),
              _c(
                VCard,
                { staticStyle: { width: "200px" } },
                [
                  _vm.selectUserId
                    ? _c(
                        VList,
                        { attrs: { dense: "" } },
                        [
                          _c(VSubheader, [
                            _vm._v(
                              "\n                        Checkpoints\n                    "
                            ),
                          ]),
                          _vm._l(
                            _vm.userResults[_vm.selectUserId],
                            function (item, idx) {
                              return _c(
                                VListItem,
                                { key: idx },
                                [
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          _vm._s(idx + 1) +
                                            ". " +
                                            _vm._s(item.text)
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemAction,
                                    [
                                      _vm.isAdmin
                                        ? _c(VCheckbox, {
                                            attrs: { success: "" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.sendCheckPoints(
                                                  _vm.selectUserId,
                                                  item
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.status,
                                              callback: function ($$v) {
                                                _vm.$set(item, "status", $$v)
                                              },
                                              expression: "item.status",
                                            },
                                          })
                                        : _vm._e(),
                                      !_vm.isAdmin
                                        ? _c(VCheckbox, {
                                            attrs: {
                                              success: "",
                                              readonly: "",
                                            },
                                            model: {
                                              value: item.status,
                                              callback: function ($$v) {
                                                _vm.$set(item, "status", $$v)
                                              },
                                              expression: "item.status",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }