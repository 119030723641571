var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { staticClass: "vuejs-countdown" }, [
    _vm.days > 0
      ? _c("li", [
          _c("p", { staticClass: "digit" }, [
            _vm._v(_vm._s(_vm._f("twoDigits")(_vm.days))),
          ]),
          _c("p", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.days > 1 ? "days" : "day")),
          ]),
        ])
      : _vm._e(),
    _c("li", [
      _c("p", { staticClass: "digit" }, [
        _vm._v(_vm._s(_vm._f("twoDigits")(_vm.hours))),
      ]),
      _c("p", { staticClass: "text" }, [
        _vm._v(_vm._s(_vm.hours > 1 ? "hours" : "hour")),
      ]),
    ]),
    _c("li", [
      _c("p", { staticClass: "digit" }, [
        _vm._v(_vm._s(_vm._f("twoDigits")(_vm.minutes))),
      ]),
      _c("p", { staticClass: "text" }, [_vm._v("min")]),
    ]),
    _c("li", [
      _c("p", { staticClass: "digit" }, [
        _vm._v(_vm._s(_vm._f("twoDigits")(_vm.seconds))),
      ]),
      _c("p", { staticClass: "text" }, [_vm._v("Sec")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }