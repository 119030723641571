var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modelingId
    ? _c("event-storming-model-canvas", {
        key: _vm.componentKey,
        ref: "designer",
        attrs: {
          elementListBeanPath: "classDefinitions",
          relationListBeanPath: "relations",
          relationVueComponentName: "class-relation",
          projectTitle: _vm.projectName,
          labsId: _vm.labInfo.labId,
          projectId: _vm.modelingId,
          isOriginalModel: _vm.isOriginalModel,
          fork: "",
        },
        on: {
          change: _vm.onResultChange,
          codeChange: _vm.onCodeChange,
          newProjectId: _vm.setLoadProjectId,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }