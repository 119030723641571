var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("drag", { staticClass: "drag", attrs: { data: _vm.data } }, [
    _vm._v(_vm._s(_vm.data)),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }