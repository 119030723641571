var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drop",
    {
      staticClass: "copy",
      on: { drop: _vm.onCopyDrop },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [_vm._v(_vm._s(_vm.value))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }