import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        "margin-top": "-30px",
        "margin-bottom": "-10px",
        "padding-top": "50px",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            float: "right",
            "margin-right": "5%",
            "margin-top": "-25px",
          },
        },
        [
          _vm.isAdmin && !_vm.quizLoading
            ? _c(
                "div",
                { staticStyle: { position: "fixed", "z-index": "999" } },
                [
                  _c(
                    VIcon,
                    {
                      attrs: { "x-large": "" },
                      on: {
                        click: function ($event) {
                          return _vm.setNewQuiz()
                        },
                      },
                    },
                    [_vm._v("mdi-plus-circle")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.quizLoading
        ? _c(
            "div",
            [
              _c(
                VCol,
                {
                  staticStyle: { "margin-left": "60px" },
                  attrs: { cols: "11" },
                },
                [
                  _c(VSkeletonLoader, { attrs: { type: "image" } }),
                  _c(VSkeletonLoader, {
                    staticStyle: { "margin-top": "30px" },
                    attrs: { type: "image" },
                  }),
                  _c(VSkeletonLoader, {
                    staticStyle: { "margin-top": "30px" },
                    attrs: { type: "image" },
                  }),
                  _c(VSkeletonLoader, {
                    staticStyle: { "margin-top": "30px" },
                    attrs: { type: "image" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            _vm._l(_vm.quizList, function (quiz, index) {
              return _c(
                VCard,
                {
                  key: index,
                  staticStyle: {
                    width: "90%",
                    "margin-left": "5%",
                    "margin-bottom": "20px",
                    "margin-top": "20px",
                  },
                },
                [
                  _c(
                    VChip,
                    {
                      staticStyle: { margin: "10px" },
                      attrs: { color: "primary" },
                    },
                    [_vm._v("Quiz " + _vm._s(index + 1))]
                  ),
                  _vm.isAdmin
                    ? _c(
                        "div",
                        { staticStyle: { float: "right", padding: "10px" } },
                        [
                          _c(
                            VIcon,
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openDeleteDialog(
                                    index,
                                    _vm.quizList
                                  )
                                },
                              },
                            },
                            [_vm._v("mdi-delete")]
                          ),
                          _c(
                            VIcon,
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onEditMode(index, _vm.quizList)
                                },
                              },
                            },
                            [_vm._v("mdi-pencil")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.setQuizNumber != index
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-left": "12px",
                            "padding-right": "12px",
                            "padding-bottom": "0",
                            "font-size": "20px",
                            "font-weight": "700",
                          },
                          style: _vm.submitted ? "pointer-events: none;" : "",
                        },
                        [
                          _c(
                            "quiz",
                            {
                              attrs: { quizId: _vm.quizIds[index] },
                              on: { change: _vm.onAnswer },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            },
                            [
                              _c("v-runtime-template", {
                                attrs: {
                                  template:
                                    "<div slot=default>" +
                                    _vm.quizList[index] +
                                    "</div>",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.setQuizNumber == index
                    ? _c(
                        "div",
                        [
                          _c(
                            VCard,
                            [
                              _c("vue-simplemde", {
                                ref: "markdownEditor",
                                refInFor: true,
                                model: {
                                  value: _vm.setQuizText,
                                  callback: function ($$v) {
                                    _vm.setQuizText = $$v
                                  },
                                  expression: "setQuizText",
                                },
                              }),
                              _c(
                                VBtn,
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    margin: "8px",
                                    "z-index": "999",
                                  },
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editQuizInfo(_vm.setQuizText)
                                    },
                                  },
                                },
                                [_vm._v("save")]
                              ),
                              _c(
                                VBtn,
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "0",
                                    "z-index": "999",
                                    right: "0",
                                    margin: "8px",
                                    "margin-right": "80px",
                                  },
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.setQuizNumber = null
                                    },
                                  },
                                },
                                [_vm._v("cancel")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
      _vm.openAddQuizInfo
        ? _c(
            VCard,
            {
              staticStyle: {
                width: "90%",
                "margin-left": "5%",
                "margin-bottom": "20px",
                "margin-top": "20px",
              },
            },
            [
              _c(
                VChip,
                {
                  staticStyle: { margin: "10px" },
                  attrs: { color: "primary" },
                },
                [_vm._v("Quiz " + _vm._s(_vm.newQuizNumber + 1))]
              ),
              _c(
                "div",
                { staticStyle: { padding: "20px" } },
                [
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        float: "right",
                        "margin-top": "-60px",
                        "margin-right": "10px",
                      },
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addQuiz(_vm.newQuizText, _vm.newQuizNumber)
                        },
                      },
                    },
                    [_vm._v("생성")]
                  ),
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        float: "right",
                        "margin-top": "-60px",
                        "margin-right": "80px",
                      },
                      on: {
                        click: function ($event) {
                          _vm.openAddQuizInfo = false
                        },
                      },
                    },
                    [_vm._v("취소")]
                  ),
                  _c("vue-simplemde", {
                    ref: "markdownEditor",
                    model: {
                      value: _vm.newQuizText,
                      callback: function ($$v) {
                        _vm.newQuizText = $$v
                      },
                      expression: "newQuizText",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticStyle: { width: "100%", height: "50px" } }),
      !_vm.quizLoading
        ? _c(
            VBtn,
            {
              staticStyle: {
                position: "absolute",
                right: "5%",
                bottom: "20px",
              },
              attrs: {
                color: "primary",
                disabled: _vm.submitted || !_vm.isLogin,
              },
              on: {
                click: function ($event) {
                  return _vm.openSubmitDialog()
                },
              },
            },
            [_vm._v(" 이대로 제출 ")]
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            VDialog,
            {
              attrs: { width: "500" },
              model: {
                value: _vm.submitDialog,
                callback: function ($$v) {
                  _vm.submitDialog = $$v
                },
                expression: "submitDialog",
              },
            },
            [
              _c(
                VCardTitle,
                {
                  staticStyle: {
                    "background-color": "#3498db",
                    color: "white",
                  },
                },
                [_vm._v("submit")]
              ),
              _c(
                VCard,
                {
                  staticStyle: {
                    height: "120px",
                    width: "500px",
                    padding: "10px",
                  },
                },
                [
                  _c(VCheckbox, {
                    staticStyle: { "margin-top": "-4px" },
                    attrs: {
                      label: "제출하시겠습니까? 제출시 수정이 불가합니다.",
                      color: "#3498db",
                    },
                    model: {
                      value: _vm.checkToSubmit,
                      callback: function ($$v) {
                        _vm.checkToSubmit = $$v
                      },
                      expression: "checkToSubmit",
                    },
                  }),
                  _c(
                    VBtn,
                    {
                      staticStyle: { float: "right", "margin-top": "17px" },
                      attrs: {
                        disabled: !_vm.checkToSubmit,
                        text: "",
                        color: "#3498db",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submit()
                        },
                      },
                    },
                    [_c("b", [_vm._v("확인")])]
                  ),
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        float: "right",
                        "margin-top": "17px",
                        "margin-right": "7px",
                      },
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          ;(_vm.submitDialog = false),
                            (_vm.checkToSubmit = false)
                        },
                      },
                    },
                    [_vm._v("취소")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VDialog,
            {
              attrs: { width: "500" },
              model: {
                value: _vm.deleteDialog,
                callback: function ($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog",
              },
            },
            [
              _c(
                VCardTitle,
                {
                  staticStyle: {
                    "background-color": "#3498db",
                    color: "white",
                  },
                },
                [_vm._v("delete")]
              ),
              _c(
                VCard,
                {
                  staticStyle: {
                    height: "120px",
                    width: "500px",
                    padding: "10px",
                  },
                },
                [
                  _c(VCheckbox, {
                    staticStyle: { "margin-top": "-4px" },
                    attrs: {
                      label: "해당 퀴즈가 삭제됨을 확인했습니다.",
                      color: "#3498db",
                    },
                    model: {
                      value: _vm.checkToDelete,
                      callback: function ($$v) {
                        _vm.checkToDelete = $$v
                      },
                      expression: "checkToDelete",
                    },
                  }),
                  _c(
                    VBtn,
                    {
                      staticStyle: { float: "right", "margin-top": "17px" },
                      attrs: {
                        disabled: !_vm.checkToDelete,
                        text: "",
                        color: "#3498db",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteQuiz(_vm.quizIdx, _vm.setQuizList)
                        },
                      },
                    },
                    [_c("b", [_vm._v("삭제")])]
                  ),
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        float: "right",
                        "margin-top": "17px",
                        "margin-right": "7px",
                      },
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          ;(_vm.deleteDialog = false),
                            (_vm.checkToDelete = false)
                        },
                      },
                    },
                    [_vm._v("취소")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }