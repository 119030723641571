import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.selectedUser
        ? _c(
            "div",
            [
              _vm.imgUrl
                ? _c(VImg, {
                    attrs: {
                      contain: "",
                      "max-width": "600px",
                      src: _vm.imgUrl,
                    },
                    on: {
                      click: function ($event) {
                        _vm.replayDialog = true
                      },
                    },
                  })
                : _c(
                    VImg,
                    { attrs: { contain: "" } },
                    [
                      _c(VIcon, { attrs: { "x-large": "" } }, [
                        _vm._v(" mdi-cast-off"),
                      ]),
                    ],
                    1
                  ),
              _vm.diffList.length > 1
                ? _c(VSlider, {
                    attrs: { max: _vm.maxlength, min: "0" },
                    model: {
                      value: _vm.nowIndex,
                      callback: function ($$v) {
                        _vm.nowIndex = $$v
                      },
                      expression: "nowIndex",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            { staticStyle: { margin: "15px", position: "relative" } },
            [
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { position: "absolute", "z-index": "999" },
                      style: { left: _vm.clickX + "%", top: _vm.clickY + "%" },
                    },
                    [
                      _vm.clickPointer
                        ? _c("div", {
                            staticStyle: {
                              width: "30px",
                              height: "30px",
                              "border-radius": "50%",
                              background: "#ff0000",
                              opacity: "0.5",
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm.imgUrl
                    ? _c(
                        VImg,
                        {
                          attrs: { contain: "", src: _vm.imgUrl },
                          on: {
                            click: function ($event) {
                              _vm.replayListDialog = true
                            },
                          },
                        },
                        [
                          _c("resize-observer", {
                            on: { notify: _vm.handleResize },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.maxlength > 1
                ? _c(VSlider, {
                    attrs: { max: _vm.maxlength, min: "0" },
                    model: {
                      value: _vm.nowIndex,
                      callback: function ($$v) {
                        _vm.nowIndex = $$v
                      },
                      expression: "nowIndex",
                    },
                  })
                : _vm._e(),
              _c(VRow, [
                _c(
                  "div",
                  {
                    staticStyle: { margin: "0 10px 0 10px" },
                    style: _vm.imgUrl ? "display:block;" : "display:none",
                  },
                  [
                    _vm.playIcon
                      ? _c(
                          VBtn,
                          {
                            on: {
                              click: function ($event) {
                                return _vm.startPlay()
                              },
                            },
                          },
                          [
                            _c(VIcon, { attrs: { large: "" } }, [
                              _vm._v("mdi-play"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.playIcon
                      ? _c(
                          VBtn,
                          {
                            on: {
                              click: function ($event) {
                                return _vm.stopPlay()
                              },
                            },
                          },
                          [
                            _c(VIcon, { attrs: { large: "" } }, [
                              _vm._v("mdi-stop"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
      _c(
        VDialog,
        {
          attrs: {
            persistent: _vm.playing,
            "max-width": "800px",
            width: "60%",
            height: "70%",
          },
          model: {
            value: _vm.replayDialog,
            callback: function ($$v) {
              _vm.replayDialog = $$v
            },
            expression: "replayDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _vm.imgUrl
                ? _c(VImg, {
                    attrs: { src: _vm.imgUrl },
                    on: {
                      click: function ($event) {
                        _vm.dialog = true
                      },
                    },
                  })
                : _vm._e(),
              _vm.maxlength > 1
                ? _c(VSlider, {
                    attrs: { max: _vm.maxlength, min: "0" },
                    model: {
                      value: _vm.nowIndex,
                      callback: function ($$v) {
                        _vm.nowIndex = $$v
                      },
                      expression: "nowIndex",
                    },
                  })
                : _vm._e(),
              !_vm.selectedUser
                ? _c(VTextField, {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.replayName,
                      callback: function ($$v) {
                        _vm.replayName = $$v
                      },
                      expression: "replayName",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "1000px" },
          model: {
            value: _vm.replayListDialog,
            callback: function ($$v) {
              _vm.replayListDialog = $$v
            },
            expression: "replayListDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardText,
                { staticStyle: { position: "relative" } },
                [
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            "z-index": "999",
                          },
                          style: {
                            left: _vm.clickX + "%",
                            top: _vm.clickY + "%",
                          },
                        },
                        [
                          _vm.clickPointer
                            ? _c("div", {
                                staticStyle: {
                                  width: "30px",
                                  height: "30px",
                                  "border-radius": "50%",
                                  background: "#ff0000",
                                  opacity: "0.5",
                                },
                                style: {},
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm.imgUrl
                        ? _c(
                            VImg,
                            { attrs: { contain: "", src: _vm.imgUrl } },
                            [
                              _c("resize-observer", {
                                on: { notify: _vm.handleResize },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.maxlength > 1
                    ? _c(VSlider, {
                        attrs: { max: _vm.maxlength, min: "0" },
                        model: {
                          value: _vm.nowIndex,
                          callback: function ($$v) {
                            _vm.nowIndex = $$v
                          },
                          expression: "nowIndex",
                        },
                      })
                    : _vm._e(),
                  _c(
                    VBtn,
                    {
                      on: {
                        click: function ($event) {
                          return _vm.startPlay()
                        },
                      },
                    },
                    [_vm._v("Play")]
                  ),
                  _c(
                    VBtn,
                    {
                      on: {
                        click: function ($event) {
                          return _vm.stopPlay()
                        },
                      },
                    },
                    [_vm._v("Stop")]
                  ),
                  _c(
                    VBtn,
                    {
                      on: {
                        click: function ($event) {
                          return _vm.saveReplay()
                        },
                      },
                    },
                    [_vm._v("저장")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }