import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.status == "writing"
      ? _c(
          "div",
          [
            _c(VSubheader, [_vm._v("출석과 도움요청")]),
            _c(
              VBtn,
              {
                attrs: { text: "" },
                on: {
                  click: function ($event) {
                    return _vm.submitAnswer("출석")
                  },
                },
              },
              [_vm._v("출석")]
            ),
            _c(
              VBtn,
              {
                attrs: { text: "" },
                on: {
                  click: function ($event) {
                    return _vm.submitAnswer("헬프미")
                  },
                },
              },
              [_vm._v("헬프미")]
            ),
            _c(VSubheader, [_vm._v("선택형")]),
            _c(
              VBtn,
              {
                attrs: { text: "" },
                on: {
                  click: function ($event) {
                    return _vm.submitAnswer("1")
                  },
                },
              },
              [_vm._v("1")]
            ),
            _c(
              VBtn,
              {
                attrs: { text: "" },
                on: {
                  click: function ($event) {
                    return _vm.submitAnswer("2")
                  },
                },
              },
              [_vm._v("2")]
            ),
            _c(
              VBtn,
              {
                attrs: { text: "" },
                on: {
                  click: function ($event) {
                    return _vm.submitAnswer("3")
                  },
                },
              },
              [_vm._v("3")]
            ),
            _c(
              VBtn,
              {
                attrs: { text: "" },
                on: {
                  click: function ($event) {
                    return _vm.submitAnswer("4")
                  },
                },
              },
              [_vm._v("4")]
            ),
            _c(
              VBtn,
              {
                attrs: { text: "" },
                on: {
                  click: function ($event) {
                    return _vm.submitAnswer("5")
                  },
                },
              },
              [_vm._v("5")]
            ),
            _c(VSubheader, [_vm._v("예/아니오")]),
            _c(
              VBtn,
              {
                attrs: { text: "" },
                on: {
                  click: function ($event) {
                    return _vm.submitAnswer("Y")
                  },
                },
              },
              [_vm._v("Yes")]
            ),
            _c(
              VBtn,
              {
                attrs: { text: "" },
                on: {
                  click: function ($event) {
                    return _vm.submitAnswer("N")
                  },
                },
              },
              [_vm._v("No")]
            ),
            _c(VSubheader, [_vm._v("주관식")]),
            _c(VTextarea, {
              attrs: {
                id: "subjective-answer",
                outlined: "",
                name: "input-7-4",
                label: "답을 입력하세요",
              },
              model: {
                value: _vm.answer,
                callback: function ($$v) {
                  _vm.answer = $$v
                },
                expression: "answer",
              },
            }),
            _c(
              VBtn,
              {
                attrs: { large: "", text: "", color: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.submitAnswer()
                  },
                },
              },
              [_vm._v("답안 제출\n        ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.status == "submitting"
      ? _c("div", [
          _c("br"),
          _c("br"),
          _vm._v("\n        제출중입니다.... "),
          _c("br"),
          _c("br"),
          _c("h1", [_vm._v(_vm._s(_vm.answer))]),
          _c("br"),
          _c("br"),
        ])
      : _vm._e(),
    _vm.status == "submitted"
      ? _c(
          "div",
          [
            _c("br"),
            _c("br"),
            _c("font", { attrs: { color: "indigo" } }, [
              _vm._v("\n            제출되었습니다"),
              _c("br"),
              _c("br"),
              _c("h1", [_vm._v(_vm._s(_vm.answer))]),
              _c("br"),
              _c("br"),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }