import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VCheckbox, {
    attrs: { disabled: _vm.submitted, color: "#3498db", label: _vm.text },
    on: { change: _vm.onChecked },
    model: {
      value: _vm.data,
      callback: function ($$v) {
        _vm.data = $$v
      },
      expression: "data",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }