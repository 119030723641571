import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.small
    ? _c(
        "div",
        {
          staticStyle: { height: "100%", width: "100%", padding: "0px" },
          attrs: { align: "center" },
        },
        [
          _vm.classInfo.status == "completed" && !_vm.classInfo.reuse
            ? _c(
                "div",
                [
                  _c(
                    VCard,
                    {
                      staticStyle: { "margin-top": "18%" },
                      attrs: {
                        outlined: "",
                        "max-width": "550",
                        "min-width": "500",
                      },
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "text-center",
                          staticStyle: {
                            color: "#C62828",
                            "padding-top": "20px",
                            "margin-bottom": "10px",
                            "font-size": "30px",
                          },
                        },
                        [_vm._v(" 종료된 강의 입니다.")]
                      ),
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(VCardText, [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "center",
                                    margin: "15px",
                                    "word-break": "keep-all",
                                    "font-size": "20px",
                                    "line-height": "36px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            종료된 강의의 실습환경을 계속 사용하시려면 MSAEz가 별도로 제공하는 클러스터를 사용하셔야 합니다."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "사용시 비용(코인)이 소모됩니다.\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c(
                            VCardActions,
                            { staticStyle: { "margin-left": "100px" } },
                            [
                              _c(VSpacer),
                              !_vm.classInfo.ideUrl ||
                              _vm.classInfo.ideUrl == "" ||
                              _vm.labInfo.templateFile
                                ? _c(
                                    VBtn,
                                    {
                                      staticStyle: { "font-size": "20px" },
                                      attrs: { large: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFiles()
                                        },
                                      },
                                    },
                                    [_vm._v("파일 다운로드")]
                                  )
                                : _vm._e(),
                              _c(
                                VBtn,
                                {
                                  staticStyle: { "font-size": "20px" },
                                  attrs: { large: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmReuse()
                                    },
                                  },
                                },
                                [_vm._v("사용하기")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : !_vm.ideUrl
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px", height: "100%" } },
                [
                  _c(VSkeletonLoader, {
                    attrs: { type: "article,image,image,image" },
                  }),
                  _c("div", { staticClass: "gs-info-bg" }, [
                    _c("div", { staticClass: "gs-info-title" }, [
                      _vm._v(_vm._s(_vm.$t("loading.main-title"))),
                      _c("br"),
                      _vm._v("(" + _vm._s(_vm.$t("loading.main-detail")) + ")"),
                    ]),
                    _c("div", { staticClass: "gs-info-sub-bg" }, [
                      _c("div", { staticClass: "gs-info-sub-title" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("loading.sub-title"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("loading.sub-title-1")) +
                            "\n                "
                        ),
                      ]),
                      _c("div", { staticClass: "gs-info-description" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("loading.sub-detail"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("loading.sub-detail-1"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("loading.sub-detail-2"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$t("loading.sub-detail-3"))
                        ),
                        _c("br"),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    height: "100%",
                    width: "100%",
                    "background-color": "#1e1e1e",
                  },
                },
                [
                  _vm.detectedMoving
                    ? _c(
                        "div",
                        { staticStyle: { height: "100%" } },
                        [
                          _c("vue-friendly-iframe", {
                            staticStyle: {
                              height: "104%",
                              width: "100%",
                              "margin-top": "-1.2%",
                            },
                            attrs: {
                              src: _vm.ideUrl,
                              frameborder: "0",
                              gesture: "media",
                              allow: "encrypted-media",
                              name: "ideFrame",
                            },
                            on: { load: _vm.onLoad },
                          }),
                          _vm.hashName
                            ? _c("billing-counter", {
                                staticStyle: { top: "15px" },
                                attrs: {
                                  "lab-info": _vm.labInfo,
                                  "class-info": _vm.classInfo,
                                  "props-user-info": _vm.userInfo,
                                  "hash-name": _vm.hashName,
                                },
                                on: { terminate: _vm.terminate },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "45%",
                            right: "45%",
                            "text-align": "-webkit-center",
                          },
                        },
                        [
                          _c(
                            VIcon,
                            {
                              attrs: { "x-large": "", dark: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.setLocked(false)
                                },
                              },
                            },
                            [_vm._v(" mdi-lock-outline")]
                          ),
                          _c(
                            "div",
                            { staticStyle: { color: "white" } },
                            [
                              _vm._v(
                                "\n                잠금을 해제 하려면 자물쇠\n                "
                              ),
                              _c(
                                VTooltip,
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "a",
                                              _vm._g(
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setLocked(
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                            '클릭'\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    989553532
                                  ),
                                },
                                [_c("span", [_vm._v(" 해제 ")])]
                              ),
                              _vm._v("\n                하세요\n            "),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { color: "white" } },
                            [
                              _vm._v(
                                "\n                자동 잠금\n                "
                              ),
                              _c(
                                VTooltip,
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "a",
                                              _vm._g(
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setAutoLock()
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                            '변경'\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1847000203
                                  ),
                                },
                                [_c("span", [_vm._v(" 클릭  ")])]
                              ),
                              _vm._v(
                                "\n                (" +
                                  _vm._s(_vm.autoSettingTime) +
                                  ")\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              ),
        ]
      )
    : _c(
        "div",
        [
          _c(VSubheader, [_vm._v("\n        Logs\n    ")]),
          _c("log-viewer", {
            model: {
              value: _vm.value.result,
              callback: function ($$v) {
                _vm.$set(_vm.value, "result", $$v)
              },
              expression: "value.result",
            },
          }),
          _c(VDivider),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }