var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.small
    ? _c("kubernetes-model-canvas", {
        ref: "designer",
        staticClass: "small",
        attrs: {
          elementListBeanPath: "classDefinitions",
          relationListBeanPath: "relations",
          relationVueComponentName: "class-relation",
          "is-read": "true",
        },
        on: { change: _vm.onResultChange, codeChange: _vm.onCodeChange },
        model: {
          value: _vm.value.result,
          callback: function ($$v) {
            _vm.$set(_vm.value, "result", $$v)
          },
          expression: "value.result",
        },
      })
    : _c("kubernetes-model-canvas", {
        ref: "designer",
        attrs: {
          elementListBeanPath: "classDefinitions",
          relationListBeanPath: "relations",
          relationVueComponentName: "class-relation",
        },
        on: { change: _vm.onResultChange, codeChange: _vm.onCodeChange },
        model: {
          value: _vm.value.result,
          callback: function ($$v) {
            _vm.$set(_vm.value, "result", $$v)
          },
          expression: "value.result",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }