import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VNavigationDrawer,
    {
      attrs: { clipped: "", app: "", stateless: "", width: "400" },
      model: {
        value: _vm.value.drawer,
        callback: function ($$v) {
          _vm.$set(_vm.value, "drawer", $$v)
        },
        expression: "value.drawer",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "margin-top": "5px",
            "margin-right": "5px",
            "margin-bottom": "5px",
          },
          attrs: { align: "right" },
        },
        [
          _c(
            VIcon,
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.closeNavi()
                },
              },
            },
            [_vm._v("mdi-close")]
          ),
        ],
        1
      ),
      _c(VDivider),
      _c(
        VIcon,
        {
          style: { color: _vm.value.userInfo.color },
          attrs: { "x-large": "" },
        },
        [_vm._v("\n        mdi-account-circle\n    ")]
      ),
      _vm._v(
        "\n    " +
          _vm._s(_vm.value.userInfo.name) +
          " - " +
          _vm._s(_vm.value.userInfo.email) +
          "\n    "
      ),
      _c(
        VList,
        { attrs: { dense: "" } },
        [
          _c(VSubheader, [_vm._v("\n            CheckPoint\n        ")]),
          _vm._l(_vm.value.userInfo.labsData.checkPoints, function (item, idx) {
            return _c(
              VListItem,
              { key: idx },
              [
                _c(
                  VListItemContent,
                  [
                    _c(VListItemTitle, [
                      _vm._v(_vm._s(idx + 1) + ". " + _vm._s(item.text)),
                    ]),
                  ],
                  1
                ),
                _c(
                  VListItemAction,
                  [
                    _vm.renderComponent && (item.javascript || item.regExp)
                      ? _c(VCheckbox, {
                          attrs: { success: "", readonly: "" },
                          model: {
                            value: item.status,
                            callback: function ($$v) {
                              _vm.$set(item, "status", $$v)
                            },
                            expression: "item.status",
                          },
                        })
                      : _vm.renderComponent
                      ? _c(VCheckbox, {
                          attrs: { success: "" },
                          model: {
                            value: item.status,
                            callback: function ($$v) {
                              _vm.$set(item, "status", $$v)
                            },
                            expression: "item.status",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(VDivider),
      _vm.value.userInfo.logs
        ? _c(
            VList,
            { attrs: { dense: "" } },
            [
              _c(VSubheader, [_vm._v("\n            Logs\n        ")]),
              _c("log-viewer", {
                model: {
                  value: _vm.value.userInfo.logs,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.userInfo, "logs", $$v)
                  },
                  expression: "value.userInfo.logs",
                },
              }),
            ],
            1
          )
        : _c(
            VList,
            { attrs: { dense: "", align: "center" } },
            [
              _c(VSubheader, [_vm._v("\n            Logs\n        ")]),
              _vm._v("\n        로그 없음.\n    "),
            ],
            1
          ),
      _c(VDivider),
      _vm.isAdmin || _vm.value.userInfo.email == _vm.email
        ? _c(
            VBtn,
            {
              attrs: { primary: "" },
              on: {
                click: function ($event) {
                  return _vm.startLab(
                    _vm.value.userInfo.labsData,
                    _vm.value.userInfo.email
                  )
                },
              },
            },
            [_vm._v("Start\n    ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }