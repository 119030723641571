import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.small
    ? _c(
        "div",
        {
          staticStyle: { height: "100%", width: "100%" },
          attrs: { align: "center" },
        },
        [
          !_vm.ideUrl
            ? _c(
                VProgressCircular,
                {
                  staticStyle: {
                    "margin-top": "25%",
                    color: "#1976d2 !important",
                  },
                  attrs: { size: 130, color: "primary", indeterminate: "" },
                },
                [
                  _c("a", [
                    _vm._v("별도의 탭에 jupyter가 실행됩니다. (2분 가량 소요)"),
                  ]),
                ]
              )
            : _c("vue-friendly-iframe", {
                staticStyle: { height: "100%", width: "100%" },
                attrs: {
                  src: _vm.ideUrl,
                  frameborder: "0",
                  gesture: "media",
                  allow: "encrypted-media",
                },
              }),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(VSubheader, [_vm._v("\n        Notebook\n    ")]),
          _c("div", {
            staticClass: "py-3",
            staticStyle: { transform: "scale(0.7) translate(5%,-21%)" },
            domProps: { innerHTML: _vm._s(_vm.notebook) },
          }),
          _c(VDivider),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }